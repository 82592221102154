import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../FindProspects/FindProspects.scss";
import { useNavigate } from "react-router-dom";
import excelIcon from "../../assets/images/csvIcon.png";
import { Formik, Form } from "formik";
import { findProspectValidation } from "../../utils/Validations/Validations";
import excelTemplate from "../../assets/alltemplates/abmtemplate.csv";
import { saveAs } from "file-saver";
import _ from "lodash"; // For debounce functionality
import { InputText } from "primereact/inputtext";
import {
    AddCampains,
    findProspectDetails,
    getGeoLocation,
    getPeospectDetails,
    getStandardUserDetails,
    getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { capitalize } from "../../utils/Validations/ComonFunctions";
import Information from "../BuildICP/ICPResult/Information";
import InformationStaff from "../BuildICP/ICPResult/InformationStaff";
import SearchModel from "../BuildICP/Modals/ExportFile/SearchModel";
import FreeTrial from "../FindProspects/Modals/ExportProspectFile/FreeTrial";
import JobTitlesData from '../FindProspects/JobTitlesData.json';
import TopBarProgress from "react-topbar-progress-indicator";
import icpjson from "../../json/icp.json"
import CampaignsInformation from "./CampaignInformation";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { FirstLetterCapital } from "../../utils/constants";


TopBarProgress.config({
    barColors: {
        "0": "#ff4500",
        "1.0": "#ff4500"
    },
    shadowBlur: 5
});

const AddCampaign = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.user);
    const token = useSelector((state) => state?.user?.userInfo?.token);
    const isSubscribed = useSelector(
        (state) => state?.userSubscriptionData?.userSubscrptionData
    );
    const isStaff = userData.userInfo.is_staff;
    const [staffDetails, setStaffDetails] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [getAllGeolocation, setGeoLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newJobTitle, setNewJobTItle] = useState("")
    const [otherRevenue, SetOtherRevenue] = useState("")
    const [showRevenueInput, setShowRevenueInput] = useState(false)
    const [showInput, setShowInput] = useState(false)

    const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
    const initialValues = {
        job_title: [],
        job_function: [],
        job_level: [],
        revenue: [],
        upload_file: null,
        location: [],
        campaign_name: '',
        industry: [],
        employee_size: []

    };
    const customStyles = {
        option: (base, { isSelected }) => {
            return {
                ...base,
                color: isSelected ? "#414141" : "#414141",
                backgroundColor: isSelected ? "#fafafa" : "#ffffff",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "#fafafa",
                },
                "&:focus": {
                    backgroundColor: "#fafafa",
                },
            };
        },

        control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: "#ffffff",
            padding: "0",
            border: "none",
            borderBottom: "1px solid #E6E6E6",
            boxShadow: "none",
            borderRadius: "0",
            "&:hover": {
                borderColor: "#E6E6E6",
                backgroundColor: "transparent",
            },
            "&:focus": {
                borderColor: "#E6E6E6",
                backgroundColor: "transparent",
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "6px 0",
            maxHeight: "100px",
            overflowY: "auto"
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#414141",
            },
        }),
        Input: (base) => ({
            ...base,
            padding: "0",
            margin: "0",
        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            color: "#414141",
            margin: "0",
        }),
    };
    const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

    const [getRevenue, setGetRevenue] = useState([]);
    const [getJobFunction, setGetJobFunction] = useState([]);
    const [getJobLevel, setGetJobLevel] = useState([]);
    const [getEmployeeSize, setEmployeeSize] = useState([]);
    const [getIndustry, setIndustry] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState("")
    const [revenueIsOpen, setRevenueIsOpen] = useState("")


    useEffect(() => {
        if (isSubscribed?.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
        }
    }, [isSubscribed]);

    useEffect(() => {
        if (userId) {
            const payload = {
                user_id: userId,
            };
            dispatch(
                getUserSubscriptionPlanDetails(payload, token, (result) => {
                    if (result?.status === 200) {
                        setGetUserSubscriptionData(result?.data);
                    }
                })
            );
        }
    }, []);

    useEffect(() => {
        if (userData.userInfo.is_staff) {
            let id = userData.userInfo?.user_staff?.user;
            dispatch(
                getStandardUserDetails({ user_id: id }, token, (result) => {
                    setStaffDetails(result);

                })
            );
        }
    }, []);

    // for data of proppect details
    useEffect(() => {
        dispatch(
            getPeospectDetails(token, (result) => {
                // Adding "Select All" option for revenue
                const convertedRevenue = [
                    { value: "selectAll", label: "Select All" },

                    ...result.data.revenue.map((item) => ({
                        value: item.revenue_range,
                        label: item.revenue_range,
                    })),
                    { value: "Other", label: "Other" }
                ];

                // Adding "Select All" option for job function
                const convertedJobFunction = [
                    { value: "selectAll", label: "Select All" },
                    ...result.data.job_function.map((item) => ({
                        value: item.job_function,
                        label: item.job_function,
                    })),
                ];

                // Adding "Select All" option for job level
                const convertedJobLevel = [
                    { value: "selectAll", label: "Select All" },
                    ...result.data.job_level.map((item) => ({
                        value: item.job_level,
                        label: item.job_level,
                    })),
                ];
                const employeeSize = [
                    { value: "selectAll", label: "Select All" },
                    ...result.data.company_size.map((item) => ({
                        value: item.company_size,
                        label: item.company_size,
                    })),
                ];

                const convertedIndustry = [
                    { value: "selectAll", label: "Select All" },
                    ...result.data.industry.map((item) => ({
                        value: item.main_industry_name,
                        label: item.main_industry_name,
                    })),
                ];
                setGetRevenue(convertedRevenue);
                setGetJobFunction(convertedJobFunction);
                setGetJobLevel(convertedJobLevel);
                setEmployeeSize(employeeSize)
                setIndustry(convertedIndustry)
            })
        );
    }, []);


    useEffect(() => {
        dispatch(
            getGeoLocation(token, (result) => {
                const convertedData = [
                    { value: "selectAll", label: "Select All" },
                    ...result.map((item) => ({
                        value: item.country,
                        label: FirstLetterCapital(item.country),
                    })),
                ];
                setGeoLocation(convertedData);
            })
        );
    }, []);

    // Function to handle download button click
    const downloadTemplate = (templateFileName) => {
        // Construct the URL for the Excel template file
        const templateUrl = excelTemplate;

        // Use file-saver library to trigger the download
        saveAs(templateUrl, templateFileName);
    };
    //parse function for abm content for both csv and XLSX


    const handleClose = () => setIsDialogOpen(false);
    const handleSubmit = (values) => {
        const formData = new FormData();
        formData.append("user", userId);
        if (values.upload_file) {
            formData.append("upload_file", values.upload_file);

        }

        formData.append("campaign_name", values.campaign_name);
        if (values.job_title && Array.isArray(values.job_title)) {
            values.job_title.forEach(item => formData.append("job_title", item));
        }

        if (values.job_function && Array.isArray(values.job_function)) {
            values.job_function.forEach(item => formData.append("job_function", item));
        }

        if (values.job_level && Array.isArray(values.job_level)) {
            values.job_level.forEach(item => formData.append("job_level", item));
        }

        if (values.revenue && Array.isArray(values.revenue)) {
            values.revenue.forEach(item => formData.append("revenue", item));
        }

        if (values.location && Array.isArray(values.location)) {
            values.location.forEach(item => formData.append("location", item));
        }

        if (values.industry && Array.isArray(values.industry)) {
            values.industry.forEach(item => formData.append("industry", item));
        }

        if (values.employee_size && Array.isArray(values.employee_size)) {
            values.employee_size.forEach(item => formData.append("employee_size", item));
        }

        dispatch(
            AddCampains(formData, token, (result) => {
                navigate("/campaign-list")
            })
        );
    };
    const handelAddOther = (type) => {
        if (type === "revenue") {
            setGetRevenue((pre) => ([
                ...pre,
                { value: otherRevenue, label: otherRevenue }
            ]))
            SetOtherRevenue("")
        } else {
            JobTitlesData.items.push(newJobTitle)
            setNewJobTItle("")
        }

    }
    return (
        <>
            <div className="pageHeadingBlock commonHeading ">
                <div className="pageTitle">
                    <ICPTitle title="Build campaign" backTo="/campaign-list" />
                </div>
                <CampaignsInformation getUserSubscriptionData={getUserSubscriptionData} />
            </div>
            {/* {isSubscribed?.data?.is_free_trial && <FreeTrial />} */}
            <div className="pageContentBlock">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="contentWrapper FindProspects">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={findProspectValidation}
                            >
                                {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    touched,
                                    errors,
                                }) => (

                                    <Form className="FindProspectusForm">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="icpForm">
                                                    <div className="form-group" style={{ display: "flex" }}>

                                                        <div style={{ width: "100%" }} >
                                                            <label>
                                                                Campaign  <span className="required">&#42;</span>
                                                            </label>
                                                            <InputText

                                                                onBlur={handleBlur}
                                                                onChange={(e) => setFieldValue('campaign_name', e.target.value)}
                                                                className="form-control"
                                                                style={{
                                                                    border: "none",
                                                                    borderBottom: "1px solid #E6E6E6",
                                                                    width: "100%",
                                                                    borderColor: (touched.campaign_name && errors.campaign_name) ? "red" : "hsl(0, 0%, 80%);"
                                                                }}
                                                                placeholder="Enter Campaign Name"
                                                                name="campaign_name"
                                                                id="campaign_name"
                                                            />
                                                            {(touched.campaign_name || errors.campaign_name) && (
                                                                <div className="error-message">
                                                                    {errors.campaign_name}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="form-group" style={{ display: "flex" }}>

                                                        <div style={{ width: "100%" }} >
                                                            <label>
                                                                Job Title
                                                            </label>


                                                            <Select
                                                                options={JobTitlesData.items.map((item) => ({
                                                                    value: item,
                                                                    label: item
                                                                }))}
                                                                styles={customStyles}
                                                                closeMenuOnSelect={false}
                                                                className={`selectBox ${touched.job_title && errors.job_title ? "error-input" : ""}`}
                                                                name="job_title"
                                                                value={JobTitlesData.items
                                                                    .map((item) => ({
                                                                        value: item,
                                                                        label: item
                                                                    }))
                                                                    .filter((option) => values.job_title.includes(option.value))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions.map((option) => option.value);
                                                                    // Handle "Select All" logic
                                                                    if (selectedValues.includes("Select All")) {
                                                                        // Deselect all if "Select All" is the only selection
                                                                        if (selectedValues.length === JobTitlesData.items.length) {
                                                                            setFieldValue("job_title", []);
                                                                        } else {
                                                                            // setShowInput(true)
                                                                            // Otherwise, select all options except "Select All"
                                                                            const allJobTitles = JobTitlesData.items.filter((option) => option !== "Select All" && option !== "Other");
                                                                            setFieldValue("job_title", allJobTitles);
                                                                        }
                                                                    } else if (selectedValues.includes("Other")) {
                                                                        setShowInput(true)
                                                                        setFieldValue("job_title", selectedValues.filter((ele) => ele != "Other"));
                                                                        setMenuIsOpen(false);
                                                                    } else {
                                                                        // Normal multi-selection logic
                                                                        setFieldValue("job_title", selectedValues);
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                                isMulti
                                                                onMenuOpen={() => setMenuIsOpen(true)}  // Open the dropdown when clicked
                                                                onMenuClose={() => setMenuIsOpen(false)}
                                                                menuIsOpen={menuIsOpen}
                                                            />
                                                            {touched.job_title && errors.job_title && (
                                                                <div className="error-message">
                                                                    {errors.job_title}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    {showInput && (
                                                        <div style={{ width: "100%", position: "relative" }} className="form-group">
                                                            <InputText
                                                                className="form-control"
                                                                onChange={(e) => setNewJobTItle(e.target.value)}
                                                                style={{
                                                                    border: "none",
                                                                    borderBottom: "1px solid #E6E6E6",
                                                                    width: "100%",
                                                                    borderColor: "hsl(0, 0%, 80%);",
                                                                    paddingRight: "25px", // Add space for the checkmark icon

                                                                }}
                                                                value={newJobTitle}
                                                                placeholder="Enter other job title"
                                                                name="new_job_title"
                                                                id="new_job_title"
                                                                onBlur={handleBlur}
                                                            />
                                                            {/* Checkmark Icon */}
                                                            <span className="job-title-add-span" onClick={() => {
                                                                if (newJobTitle) {
                                                                    console.log("new_job_title", newJobTitle)
                                                                    handelAddOther("job_title")
                                                                    values.job_title.push(newJobTitle)
                                                                }

                                                            }}
                                                            >
                                                                &#10003; {/* HTML entity for a checkmark */}
                                                            </span>
                                                            {"    "}
                                                            <span className="job-title-add-span-cross" onClick={() => setShowInput(false)}
                                                            >
                                                                &#10005;
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="form-group">
                                                        <label>
                                                            Job Function{" "}
                                                            <span className="required">&#42;</span>
                                                        </label>
                                                        <Select
                                                            options={getJobFunction}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            className={`selectBox ${touched.job_function && errors.job_function
                                                                ? "error-input"
                                                                : ""
                                                                }`}
                                                            placeholder="Select"
                                                            name="job_function"
                                                            value={getJobFunction.filter((option) =>
                                                                values.job_function.includes(option.value)
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                // Check if "Select All" is selected
                                                                if (
                                                                    selectedOptions.some(
                                                                        (option) => option.value === "selectAll"
                                                                    )
                                                                ) {
                                                                    if (
                                                                        selectedOptions.length ===
                                                                        getJobFunction.length
                                                                    ) {
                                                                        // If all options are selected, deselect all
                                                                        setFieldValue("job_function", []);
                                                                    } else {
                                                                        // Select all options except "Select All"
                                                                        setFieldValue(
                                                                            "job_function",
                                                                            getJobFunction
                                                                                .filter(
                                                                                    (option) =>
                                                                                        option.value !== "selectAll"
                                                                                )
                                                                                .map((option) => option.value)
                                                                        );
                                                                    }
                                                                } else {
                                                                    // Normal selection
                                                                    setFieldValue(
                                                                        "job_function",
                                                                        selectedOptions.map(
                                                                            (option) => option.value
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            isMulti
                                                        />
                                                        {touched.job_function && errors.job_function && (
                                                            <div className="error-message">
                                                                {errors.job_function}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            Job Level <span className="required">&#42;</span>
                                                        </label>{" "}
                                                        <Select
                                                            options={getJobLevel}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            className={`selectBox ${touched.job_level && errors.job_level
                                                                ? "error-input"
                                                                : ""
                                                                }`}
                                                            placeholder="Select"
                                                            name="job_level"
                                                            value={getJobLevel.filter((option) =>
                                                                values.job_level.includes(option.value)
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                // Check if "Select All" is selected
                                                                if (
                                                                    selectedOptions.some(
                                                                        (option) => option.value === "selectAll"
                                                                    )
                                                                ) {
                                                                    if (
                                                                        selectedOptions.length ===
                                                                        getJobLevel.length
                                                                    ) {
                                                                        // If all options are selected, deselect all
                                                                        setFieldValue("job_level", []);
                                                                    } else {
                                                                        // Select all options except "Select All"
                                                                        setFieldValue(
                                                                            "job_level",
                                                                            getJobLevel
                                                                                .filter(
                                                                                    (option) =>
                                                                                        option.value !== "selectAll"
                                                                                )
                                                                                .map((option) => option.value)
                                                                        );
                                                                    }
                                                                } else {
                                                                    setFieldValue(
                                                                        "job_level",
                                                                        selectedOptions.map(
                                                                            (option) => option.value
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            isMulti
                                                        />
                                                        {touched.job_level && errors.job_level && (
                                                            <div className="error-message">
                                                                {errors.job_level}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            Geolocation{" "}
                                                            <span className="required">&#42;</span>
                                                        </label>
                                                        <Select
                                                            options={getAllGeolocation}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            placeholder="Select"
                                                            className={`selectBox ${touched.location && errors.location
                                                                ? "error-input"
                                                                : ""
                                                                }`}
                                                            name="location"
                                                            value={getAllGeolocation.filter((option) =>
                                                                values.location.includes(option.value)
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                if (
                                                                    selectedOptions.some(
                                                                        (option) => option.value === "selectAll"
                                                                    )
                                                                ) {
                                                                    if (
                                                                        selectedOptions.length ===
                                                                        getAllGeolocation.length
                                                                    ) {
                                                                        // If all options are selected, deselect all
                                                                        setFieldValue("location", []);
                                                                    } else {
                                                                        // Select all options except "Select All"
                                                                        setFieldValue(
                                                                            "location",
                                                                            getAllGeolocation
                                                                                .filter(
                                                                                    (option) =>
                                                                                        option.value !== "selectAll"
                                                                                )
                                                                                .map((option) => option.value)
                                                                        );
                                                                    }
                                                                } else {
                                                                    // Normal selection
                                                                    setFieldValue(
                                                                        "location",
                                                                        selectedOptions
                                                                            ? selectedOptions.map(
                                                                                (option) => option.value
                                                                            )
                                                                            : []
                                                                    );
                                                                }
                                                            }}
                                                            isMulti
                                                            onBlur={handleBlur}
                                                        />
                                                        {touched.location && errors.location && (
                                                            <div className="error-message">
                                                                {errors.location}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            Employee Size

                                                        </label>{" "}
                                                        <Select
                                                            options={getEmployeeSize}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            className={`selectBox ${touched.employee_size && errors.employee_size
                                                                ? "error-input"
                                                                : ""
                                                                }`}
                                                            placeholder="Select"
                                                            name="employee_size"
                                                            value={getEmployeeSize.filter((option) =>
                                                                values?.employee_size?.includes(option.value)
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                // Check if "Select All" is selected
                                                                if (
                                                                    selectedOptions.some(
                                                                        (option) => option.value === "selectAll"
                                                                    )
                                                                ) {
                                                                    if (
                                                                        selectedOptions.length ===
                                                                        getEmployeeSize.length
                                                                    ) {
                                                                        // If all options are selected, deselect all
                                                                        setFieldValue("employee_size", []);
                                                                    } else {
                                                                        // Select all options except "Select All"
                                                                        setFieldValue(
                                                                            "employee_size",
                                                                            getEmployeeSize
                                                                                .filter(
                                                                                    (option) =>
                                                                                        option.value !== "selectAll"
                                                                                )
                                                                                .map((option) => option.value)
                                                                        );
                                                                    }
                                                                } else {
                                                                    setFieldValue(
                                                                        "employee_size",
                                                                        selectedOptions.map(
                                                                            (option) => option.value
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            isMulti
                                                        />
                                                        {touched.employee_size && errors.employee_size && (
                                                            <div className="error-message">
                                                                {errors.employee_size}
                                                            </div>
                                                        )}
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="icpForm">
                                                    <div className="form-group">
                                                        <label>
                                                            Revenue
                                                        </label>{" "}
                                                        <Select
                                                            options={getRevenue}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            className={`selectBox ${touched.revenue && errors.revenue ? "error-input" : ""}`}
                                                            placeholder="Select"
                                                            name="revenue"
                                                            value={getRevenue.filter(option => values?.revenue?.includes(option.value))}
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions.map(option => option.value);

                                                                // Handle "Select All" logic
                                                                if (selectedValues.includes("selectAll")) {
                                                                    // Deselect all if "Select All" is clicked again
                                                                    if (selectedValues.length === getRevenue.length) {
                                                                        setFieldValue("revenue", []);
                                                                    } else {
                                                                        // Select all options except "Select All" and "Other"
                                                                        setFieldValue(
                                                                            "revenue",
                                                                            getRevenue
                                                                                .filter(option => option.value !== "selectAll" && option.value !== "Other")
                                                                                .map(option => option.value)
                                                                        );
                                                                    }
                                                                } else if (selectedValues.includes("Other")) {
                                                                    setShowRevenueInput(true)
                                                                    setRevenueIsOpen(false)
                                                                    setFieldValue("revenue", selectedValues.filter(value => value !== "Other"));
                                                                } else {

                                                                    setFieldValue("revenue", selectedValues.filter(value => value !== "Other"));
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            isMulti
                                                            onMenuOpen={() => setRevenueIsOpen(true)}  // Open the dropdown when clicked
                                                            onMenuClose={() => setRevenueIsOpen(false)}
                                                            menuIsOpen={revenueIsOpen}
                                                        />





                                                    </div>
                                                    {showRevenueInput && (
                                                        <div style={{ width: "100%", position: "relative" }} className="form-group">
                                                            <InputText
                                                                className="form-control"
                                                                onChange={(e) => SetOtherRevenue(e.target.value)}
                                                                style={{
                                                                    border: "none",
                                                                    borderBottom: "1px solid #E6E6E6",
                                                                    width: "100%",
                                                                    borderColor: "hsl(0, 0%, 80%);",
                                                                    paddingRight: "25px", // Add space for the checkmark icon

                                                                }}
                                                                value={otherRevenue}
                                                                placeholder="Enter other revenue"
                                                                name="other_revenue"
                                                                id="other_revenue"
                                                                onBlur={handleBlur}
                                                            />
                                                            {/* Checkmark Icon */}
                                                            <span className="job-title-add-span" onClick={() => {
                                                                if (otherRevenue) {
                                                                    console.log("new_job_title", otherRevenue)
                                                                    handelAddOther("revenue")
                                                                    values.revenue.push(otherRevenue)
                                                                }

                                                            }}
                                                            >
                                                                &#10003; {/* HTML entity for a checkmark */}
                                                            </span>
                                                            {"    "}
                                                            <span className="job-title-add-span-cross" onClick={() => setShowRevenueInput(false)}
                                                            >
                                                                &#10005;
                                                            </span>
                                                        </div>
                                                    )}

                                                    <div className="form-group">
                                                        <label>
                                                            Industry {" "}
                                                            {/* <span className="required">&#42;</span> */}
                                                        </label>
                                                        <Select
                                                            options={getIndustry}
                                                            styles={customStyles}
                                                            closeMenuOnSelect={false}
                                                            placeholder="Select"
                                                            className={`selectBox ${touched.industry && errors.industry
                                                                ? "error-input"
                                                                : ""
                                                                }`}
                                                            name="industry"
                                                            value={getIndustry.filter((option) =>
                                                                values.industry.includes(option.value)
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                if (
                                                                    selectedOptions.some(
                                                                        (option) => option.value === "selectAll"
                                                                    )
                                                                ) {
                                                                    if (
                                                                        selectedOptions.length ===
                                                                        getIndustry.length
                                                                    ) {
                                                                        // If all options are selected, deselect all
                                                                        setFieldValue("industry", []);
                                                                    } else {
                                                                        // Select all options except "Select All"
                                                                        setFieldValue(
                                                                            "industry",
                                                                            getIndustry
                                                                                .filter(
                                                                                    (option) =>
                                                                                        option.value !== "selectAll"
                                                                                )
                                                                                .map((option) => option.value)
                                                                        );
                                                                    }
                                                                } else {
                                                                    // Normal selection
                                                                    setFieldValue(
                                                                        "industry",
                                                                        selectedOptions
                                                                            ? selectedOptions.map(
                                                                                (option) => option.value
                                                                            )
                                                                            : []
                                                                    );
                                                                }
                                                            }}
                                                            isMulti
                                                            onBlur={handleBlur}
                                                        />
                                                        {touched.industry && errors.industry && (
                                                            <div className="error-message">
                                                                {errors.industry}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            Upload Company Lists{" "}
                                                            {/* <span className="required">&#42;</span> */}
                                                        </label>
                                                        <div className="uploadedFile">
                                                            <div className="fileDetilsItem">
                                                                <p>
                                                                    <img src={excelIcon} alt="" />
                                                                    campaign Template
                                                                </p>
                                                                <div className="downloadFile">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btnPrimaryOutline"
                                                                        onClick={() =>
                                                                            downloadTemplate("campaign template.csv")
                                                                        }
                                                                    >
                                                                        Download
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div /* className="uploadDocuments" */
                                                            className={`uploadDocuments ${touched.upload_file &&
                                                                errors.upload_file
                                                                ? "box"
                                                                : ""
                                                                }`}
                                                        >
                                                            <input
                                                                className="uploadDocInput"
                                                                id="upload_file"
                                                                type="file"
                                                                accept=".xlsx, .csv"
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        "upload_file",
                                                                        e.target.files[0]
                                                                    )
                                                                }
                                                            />

                                                            <div className="uploadDocContent">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="49"
                                                                    height="34"
                                                                    viewBox="0 0 49 34"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M39.5063 12.9996C38.8205 9.52414 36.9493 6.39456 34.2123 4.14543C31.4754 1.8963 28.0425 0.667168 24.5 0.667969C18.5996 0.667969 13.475 4.0163 10.9229 8.9163C7.92214 9.24058 5.14706 10.6624 3.13087 12.9084C1.11469 15.1545 -0.000350558 18.0664 8.26727e-08 21.0846C8.26727e-08 27.8426 5.49208 33.3346 12.25 33.3346H38.7917C44.4267 33.3346 49 28.7613 49 23.1263C49 17.7363 44.8146 13.3671 39.5063 12.9996ZM28.5833 19.043V27.2096H20.4167V19.043H14.2917L23.7854 9.54922C24.1938 9.14089 24.8267 9.14089 25.235 9.54922L34.7083 19.043H28.5833Z"
                                                                        fill="#414141"
                                                                        fillOpacity="0.2"
                                                                    />
                                                                </svg>

                                                                {values?.upload_file?.name ? (
                                                                    <p>{values?.upload_file?.name}</p>
                                                                ) : (
                                                                    <p className="m-0">
                                                                        Select/Drop File to upload
                                                                        <span className="m-0">
                                                                            Must be .xlsx / .csv file Use the sample
                                                                            template.
                                                                        </span>
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {touched.upload_file &&
                                                            errors.upload_file && (
                                                                <div className="error-message">
                                                                    {errors.upload_file}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group formSubmit m-0">
                                                        <button
                                                            type="submit"
                                                            className="btn btnPrimaryOutline"
                                                        >
                                                            Build My Campaign
                                                        </button>
                                                        {/* <p className="icpNote">
                              <strong>Note: </strong> Each 'Find Prospect'
                              action deducts one search from your available
                              credits.
                            </p> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    {isDialogOpen && (
                        <SearchModel
                            isDialogOpen={isDialogOpen}
                            message="Unable to search. Search Limit Reached!!! You don't have any credit remaining."
                            handleClose={handleClose}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default AddCampaign;
