import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data,selectedSubType }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      // yaxis: {
      //   title: {
      //     text: "$ (thousands)",
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} Deliverables`,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const series = [];
      const categories = Object.keys(data);
      const categoryNames = Object.keys(data[categories[0]]|| {});
      categoryNames?.forEach((category) => {
        const categoryData = categories.map(
          (region) => data[region]?.[category] || 0
        );
        series.push({
          name: category,
          data: categoryData,
        });
      });

      setChartData((prev) => ({
        ...prev,
        series,
        options: {
          ...prev.options,
          xaxis: {
            categories,
          },
        },
      }));
    }
  }, [data]);

  return (
    <div>
      <div id="chart">
        {chartData?.series?.length>0&&<ReactApexChart
          options={{
            ...chartData?.options,
            title: {
              text: `${selectedSubType.charAt(0).toUpperCase() + selectedSubType.slice(1)} Deliverables  `, // Set your title text here
              align: 'left', // Title alignment (left, center, right)
              style: {
                fontSize: '16px', // Font size
                fontWeight: 'bold', // Font weight
                fontFamily: 'Arial', // Font family
              },
            },
          }}
          series={chartData?.series}
          type="bar"
          height={350}
        />}
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default BarChart;