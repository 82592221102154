import React, { useState, useEffect, useMemo } from "react";
import "./BuildMyCampaign.scss"
import { useDispatch, useSelector } from "react-redux";
import {
  AcceptCampains,
  getCampaignGraphTableData,
  getUserSubscriptionPlanDetails
} from "../../context/actions/User";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import { ButtonGroup } from "./ButtonGroup";
import { REVENUE_MAP, COMPANY_SIZE_MAP } from "../Dashboard/FormLables";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import TableChart from "./TableChart";
import CampaignsInformation from "./CampaignInformation";
import { toast } from "react-toastify";
import { useNavigation, useParams } from "react-router-dom";

import AcceptModal from "./AcceptModal";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import ToastModal from "../../components/Modal/TosterModel";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,

  Filler
);



const BuildMyCampaign = () => {

  const dispatch = useDispatch();
  const navigate = useNavigation();
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userData = useSelector((state) => state?.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("30");
  const [customDateRange, setCustomDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [chartType, setChartType] = useState("table");
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const [selectedType, setSelectedType] = useState("deliverables");
  const [selectedSubType, setSelectedSubType] = useState("job_level");
  const [openModal, setOpenModal] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [tableData, setTableDate] = useState([])
  const [graphData, setGraphData] = useState({})
  const [toastData, setToastData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [comment, setComment] = useState("")
  const [job_function, setJob_function] = useState([])
  const { id } = useParams();


  //Calling API to getting all details
  useEffect(() => {
    if (id) {
      const payload = {
        id: id,
      };
      dispatch(
        getCampaignGraphTableData(payload, token, (result) => {
          console.log("RESULTS ", result);
          setGraphData(result)
          setComment(result?.comment)
          setJob_function(result?.job_function)
          // "job_function"

          // setGetUserData(result);
        })
      );
    }
  }, [openModal]);

  const handleSubTypeClick = (cardType) => {
    setSelectedSubType(cardType);
  };

  const handleTypeClick = (type) => {
    setSelectedType(type);
  };

  const handleAcceptModel = () => {
    if (getUserSubscriptionData?.is_free_trial) {
      Free_Trial_Limitations();
      return;
    }
    setOpenModal(true);
  }
  const onClose = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);

          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, [openModal]);

  useEffect(() => {
    if (selectedType === "deliverables") {
      setSelectedSubType("monthly")
    } else {
      setSelectedSubType("job_level")
    }
  }, [selectedType])

  useEffect(() => {
    const tableMapping = {
      job_level: graphData?.table?.job_level,
      employee_size: graphData?.table?.employee_size,
      industry: graphData?.table?.industry_database_reach,
      monthly: graphData?.table?.monthly_deliverables,
      quarterly: graphData?.table?.quarterly_deliverables,
    };
    setTableDate(tableMapping[selectedSubType]);
  }, [selectedSubType, graphData]);
  useEffect(() => {
    const ChartMapping = {
      job_level: graphData?.data?.job_level,
      employee_size: graphData?.data?.employee_size,
      industry: graphData?.data?.industry_database_reach,
      monthly: graphData?.data?.monthly_deliverables,
      quarterly: graphData?.data?.quarterly_deliverables,
    };
    setPieData(ChartMapping[selectedSubType])
  }, [selectedSubType, graphData])

  const renderChartOrTable = useMemo(() => {
    if (chartType === "chart") {
      return selectedType === "deliverables"
        ? <BarChart data={pieData} selectedSubType={selectedSubType} />
        : <PieChart data={pieData} selectedSubType={selectedSubType} />;
    }
    return <TableChart tableRow={tableData} />;
  }, [chartType, selectedType, pieData, selectedSubType, tableData, graphData]);

  const handleSubmitAccept = (date) => {
    dispatch(
      AcceptCampains(date, id, token, (res) => {
        setOpenModal(false);
      })
    )
  }
  const Free_Trial_Limitations = () => {
    let data = {
      type: 'errorModal',
      heading: 'Feature Not Available on Free Trial!',
      message: `Upgrade your subscription to unlock this feature.`,
      is_button: true
    }
    setToastData(data);
    setIsVisible(true);
    // setTimeout(() => {
    //   setIsVisible(false);
    // }, 4000);
  }

  const renderJobFunctions = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically (if the height of the container is more than content)
        }}
      >
        {/* Wrapper div with fixed width, border, and wrapping enabled */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap', // Allow items to wrap to the next row
            gap: '4px',
            justifyContent: 'center', // Center items horizontally
            alignItems: 'center', // Center items vertically
            padding: '5px 10px',
            border: '1px solid #ccc',
            width: '850px', // Fixed width for the box
            overflow: 'hidden', // Ensures content doesn't overflow the box
            borderRadius: '10px',
            fontStyle: 'italic',
            textAlign: 'center', // Centers text inside the container
          }}
        >
         
          <div style={{ fontWeight: 'bold'}}>
            Job function:
          </div>
    
          {/* Job function items */}
          {job_function.map((ele, index) => (
            <React.Fragment key={index}>
              <div style={{ listStyleType: 'none' }}>
                {ele}
                {index < job_function.length - 1 && <span>, </span>}
                {index === job_function.length - 2 && <span> and </span>}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }, [job_function]);

  // console.log("getUserSubscriptionData", getUserSubscriptionData)
  return (
    <>
      {isVisible && <ToastModal
        type={toastData.type}
        heading={toastData.heading}
        message={toastData.message}
        is_button={toastData?.is_button}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />}
      <div className="pageHeadingBlock commonHeading">
        <ICPTitle title="Build My Campaign" backTo="/campaign-list" />
        <CampaignsInformation getUserSubscriptionData={getUserSubscriptionData} />
      </div>
      <div className="px-2 pageContentBlock">
        <div className="row">
          <div className="col-12">
            <div className="contentWrapper dashboardPage">

              <div className="row">
                <div className="col-12">
                  <div className="detailsOverviewGraph">
                    <div className="headingBlock">
                      <h5>
                        <strong>Campaign Overview </strong>
                      </h5>
                      <p className="chart-format">

                        <div class="container">
                          <div class="switches-container">


                            <input type="radio" id="switchYearly"
                              name="switchPlan"
                              value="table"
                              checked={chartType === "table"}
                              onChange={(e) => setChartType(e.target.value)}
                            />
                            <input type="radio" id="switchMonthly"
                              name="switchPlan"
                              value="chart"
                              checked={chartType === "chart"}
                              onChange={(e) => setChartType(e.target.value)}
                            />
                            <label for="switchYearly">Table</label>
                            <label for="switchMonthly">Chart</label>

                            <div class="switch-wrapper">
                              <div class="switch">
                                <div>Table</div>
                                <div>Chart</div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </p>
                    </div>

                    <div className="contentBlock">
                      <ul className="nav nav-tabs d-flex justify-content-center" id="nav-tab" role="tablist">

                        <li
                          className="nav-item"
                          role="presentation"
                        >
                          <button
                            // className="btnBlock btn-warning col-md-12 ms-3 p-0 px-3"
                            className={`btnBlock btn-primary p-0 px-3 col-md-12   ${selectedType === 'deliverables' ? 'active tabBtnActive' : ""}`}
                            id="LALsGeneratedTab"
                            data-bs-toggle="tab"
                            data-bs-target="#LALsGenerated"
                            type="button"
                            aria-controls="LALsGenerated"

                            onClick={() => handleTypeClick("deliverables")}
                          >
                            Deliverables
                          </button>
                        </li>
                        <li
                          className="nav-item "
                          role="presentation"
                        >

                          <button
                            className={`btnBlock btn-warning col-md-12 ms-3 p-0 px-3   ${selectedType === 'database_reach' ? 'active tabBtnActive' : ""}`}
                            id="ICPDownlodedTab"
                            data-bs-toggle="tab"
                            data-bs-target="#ICPDownloded"
                            type="button"
                            aria-controls="ICPDownloded"

                            onClick={() => handleTypeClick("database_reach")}
                          >
                            Database Reach
                          </button>
                        </li>

                      </ul>


                      <div className="tab-content" id="nav-tabContent">
                        {renderJobFunctions}
                        <div
                          className="tab-pane fade "
                          id="ICPDownloded"
                          role="tabpanel"
                          aria-labelledby="ICPDownlodedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            // height: "350px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}

                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              {renderChartOrTable}
                              {/* {chartType === "chart" ? <PieChart data={pieData} /> : <TableChart tableRow={tableData} />} */}
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade show active"
                          id="LALsGenerated"
                          role="tabpanel"
                          aria-labelledby="LALsGeneratedTab"
                          style={{
                            width: "100%" /* Adjust as needed */,
                            height: "400px",
                          }}
                        >
                          <div className="d-flex table-block">
                            <div className="d-flex flex-column justify-content-center">
                              <ButtonGroup
                                handleSubTypeClick={handleSubTypeClick}
                                selectedSubType={selectedSubType}
                                isDelivery={true}
                              />
                            </div>
                            <div className="flex-grow-1 graph_block">
                              {renderChartOrTable}
                              {/* {chartType === "chart" ? <BarChart data={pieData} /> : <TableChart tableRow={tableData} />} */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* start */}
          <div className="tableSelectionRecord " >
            <div className="selectedItems">
              {comment && <p style={{ color: "#5c5252" }}>
                <strong>Admin Comment: </strong>{comment}
              </p>}
              <p>
                <strong>Note:</strong>
                {getUserSubscriptionData.is_free_trial ? (
                  " Campaign Delivery is not available on Free Trial."
                ) : graphData?.status === "Accepted" ? (
                  " This campaign is already Accepted"
                ) : (
                  " If you are okay with the quotation you can proceed"
                )}
              </p>
            </div>
            <div className="exportItems">
              <button
                className={`btnSm btnBlock btn `}
                disabled={graphData?.status === "Accepted" ? true : false}
                onClick={handleAcceptModel}
              >
                {graphData?.status === "Accepted" ? "Accepted" : "Accept"}
              </button>
            </div>
          </div>
          {/* end */}
          {openModal ? (
            <AcceptModal
              showModal={openModal}
              onClose={onClose}
              getUserSubscriptionData={getUserSubscriptionData}
              handleSubmitAccept={handleSubmitAccept}
            />
          ) : null}

        </div>

      </div>
      <br></br>
    </>
  );
}

export default BuildMyCampaign
