import "rc-slider/assets/index.css";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./MyDownloadList.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

const DownloadFilters = ({
  filters,
  handleFilterChange,
  handleResetFilters,
  handleApplyFilters,
  validationError,
  setValidationError,
}) => {
  const dropdownRef = useRef(null);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage Apply button disabled state

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-menu")
    ) {
      // Click occurred outside the dropdown menu, close it
      // Call your close dropdown function here
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Function to handle changes in start and end dates
  const handleDateChange = (startDate, endDate) => {
    // Check if start date is greater than or equal to end date
    if (moment(startDate).isAfter(endDate)) {
      setValidationError("Start date should be before end date");
    } else {
      setValidationError(""); // Clear validation error if validation passes
    }
    // Call the parent function to update filters
    handleFilterChange("startDate", startDate);
    handleFilterChange("endDate", endDate);
  };

  return (
    <div className="dropdown filterContent" ref={dropdownRef}>
      <div
        className="filterDropdown drop"
        id="filterDropdown"
        data-bs-toggle="dropdown"
        // data-bs-auto-close="false"
        // aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#414141"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>{" "}
        Filters
      </div>
      <div className="dropdown-menu" aria-labelledby="filterDropdown">
        <div className="dropdownHeading">
          <p>Filter</p>
          <Link to="" onClick={handleResetFilters}>
            Reset
          </Link>
        </div>
        <div className="dropdownContent">
          <div className="cardDrop">
            <p className="title">Date Range</p>

            <form>
              <div className="row">
                <div
                  className="form-group col-12 col-sm-5"
                  onClick={(event) => event.stopPropagation()}
                >
                  <DatePicker
                    selected={filters.date.startDate}
                    showIcon
                    onChange={(date) =>
                      handleDateChange(date, filters.date.endDate)
                    }
                    maxDate={new Date()}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                  />
                </div>
                <div className="form-group col-12 col-sm-2">
                  <span className="text-center">to</span>
                </div>
                <div
                  className="form-group col-12 col-sm-5"
                  onClick={(event) => event.stopPropagation()}
                >
                  {/* <input
                    type="date"
                    placeholder="mm-dd-yyyy"
                    className="form-control"
                    value={filters.date.endDate}
                    onChange={(e) =>
                      handleDateChange(filters.date.startDate, e.target.value)
                    }
                  /> */}
                  <DatePicker
                    selected={filters.date.endDate}
                    showIcon
                    onClick={(event) => event.stopPropagation()}
                    onChange={(date) =>
                      handleDateChange(filters.date.startDate, date)
                    }
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    placeholderText="MM/DD/YYYY"
                  />
                </div>
              </div>
              {validationError && (
                <div className="error-message">{validationError}</div>
              )}
            </form>
          </div>

          <div className="cardDrop">
            <p className="title">Type</p>

            <form>
              <div className="row">
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.type.includes("ABM")}
                    onChange={() => handleFilterChange("type", "ABM")}
                  />
                  <span>ABM</span>
                </div>

                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.type.includes("LAL")}
                    onChange={() => handleFilterChange("type", "LAL")}
                  />
                  <span>LAL</span>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.type.includes("ICP")}
                    onChange={() => handleFilterChange("type", "ICP")}
                  />
                  <span>VAIS</span>
                </div>
                {/* <div className="form-group col-12 col-sm-6">
                  <input
                    type="checkbox"
                    checked={filters.type.includes("Prospect")}
                    onChange={() => handleFilterChange("type", "Prospect")}
                  />
                  <span>Prospect</span>
                </div> */}
              </div>
            </form>
          </div>
        </div>
        <div className="filterFooter">
          <button
            type="button"
            className="btn"
            onClick={handleApplyFilters}
            disabled={validationError} // Disable button if start date or end date is not selected
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadFilters;
