import React from "react";
import { Modal, Table } from "react-bootstrap";
import "../ICPResult/ICPResult.scss";
import bomboralogo from "../../../assets/images/Powered by Bombora.png";
import { intentSignal } from "../../../utils/constants";

const IntentSignalModal = (props) => {
  return (
    <>
     <style>
        {`
      .fade:not(.show) {
        opacity: 0 !important;
      }
      .close-icon {
        cursor: pointer;
        position: relative;
        top: -9px;
        right: 15px;
        font-size: 34px;
        /* font-weight: bold; */
        color: #686868;
        z-index: 1;
      }
    `}
      </style>
      <Modal {...props} centered className="modal-intent">
        <div className="tooltip-table" style={{ maxWidth: "600px" }}>
        <Modal.Header className="bombara-logo m-0">
            <h5>Master Surge </h5>
            <img
              src={bomboralogo}
              style={{ marginRight: "130px", marginBottom: "15px" }}
              alt="bomboralogo"
            />
            <span className="close-icon" onClick={props.onHide}>
              &times;
            </span>
          </Modal.Header>
          <Modal.Body closeButton>
            <div className="intent-detailed mt-1">
              <h3>{props?.data.company_name}</h3>
              {props?.data?.Topics?.length > 0 &&
              props?.data?.Topics[0]?.domain ? (
                <div className="new-link">
                  <a
                    href={`https://${props.data.Topics[0].domain}`}
                    target="_blank"
                    title="Domain"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style={{ width: "15px", height: "15px" }}
                    >
                      <path
                        d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"
                        style={{ fill: "rgb(103, 156, 222)" }}
                      />
                    </svg>
                  </a>
                  {props.data.company_linkden_url !== "" && (
                    <a
                      href={`${props.data.company_linkden_url}`}
                      target="_blank"
                      title="Company LinkedIn Url"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{
                          width: "18px",
                          height: "18px",
                        }}
                      >
                        <path
                          d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                          style={{ fill: "rgb(103, 156, 222)" }}
                        />
                      </svg>
                    </a>
                  )}
                </div>
              ) : null}
            </div>

            <div className="intent-detailed">
              <div className="topics-lebel">
                <label>Topics:</label> {props?.data?.Topics?.length}/12
              </div>
              <p className="mt-2">
                {intentSignal(props?.data?.Topics?.length)}
              </p>
            </div>
            {/* <span>{intentSignal(props?.data?.Topics?.length)}</span> */}
            <div
              className="intent-table"
              style={{
                maxWidth: "600px",
                overflowX: "auto",
                maxHeight: "150px",
                overflowY: "auto",
              }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Topic Name</th>
                    <th>Composite Score</th>
                    <th>Delta</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.data?.Topics?.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.topic}</td>
                      <td>{detail.score}</td>
                      <td>{detail.delta}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default IntentSignalModal;
