import React, { useEffect, useState } from "react";
import "./ABMResult.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "rc-slider/assets/index.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ICPTitle from "../../BuildICP/ICPResult/ICPTitle";
import Information from "../../BuildICP/ICPResult/Information";
import Filters from "../../BuildICP/ICPResult/Filters";
import Columns from "../../BuildICP/ICPResult/Columns";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import ExportFile from "../../BuildICP/Modals/ExportFile/ExportFile";
import ICPResultTable from "../../BuildICP/ICPResult/ICPResultTable";
import {
  getAbmScore,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../../context/actions/User";
import { toast } from "react-toastify";
import InformationStaff from "../../BuildICP/ICPResult/InformationStaff";
import SearchModel from "../../BuildICP/Modals/ExportFile/SearchModel";

const ABMResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  // const { state } = useLocation();
  const state = JSON.parse(localStorage.getItem('abmpayload'));
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;

  //user subscription data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  //ICP DATA after calculation

  const abc = useSelector((state) => state.icpScoreResult.icpScore.data);
  const abmData = useSelector((state) => state.icpScoreResult.icpScore
  );
  const [icpData, setIcpData] = useState(
    useSelector((state) => state.icpScoreResult.icpScore.data)
  );
  const [abmdata, Setabmdata] = useState(useSelector((state) => state.icpScoreResult.icpScore.data))
  const itemPerPage = ITEMS_PER_PAGE;
  const [is_disabled, setDisabled] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [checkSelectAll, setCheckSelectAll] = useState([]);
  const [totalRecords,setTotalRecords]=useState(abmData?.total_count?abmData?.total_count:0)
  //export selected row
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  //open Modal
  const [openModal, setOpenModal] = useState(false);
  //this dataToRender is for updating the data when the page is changing thats why taken in useState
  const [dataToRender, setDataToRender] = useState(
    icpData?.slice(0, itemPerPage)
  );
  const [downloadRemainingCounts, setDownloadRemainingCounts] = useState(0);
  const [showProductSubcategory, setShowProductSubcategory] = useState(
    state?.product_sub_category_name
  );
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  /* Column Visibility */
  const [columnVisibility, setColumnVisibility] = useState({
    companyName: true,
    icpScore: true,
    website: true,
    domain: true,
    mainIndustry: true,
    companySize: true,
    revenue: true,
    country: true,
  });
  const pageVisibility = useSelector(
    (state) =>
      state?.userSubscriptionData?.userSubscrptionData?.data

  );

  //  // sorting data
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });

  //filters for showing data
  const [filters, setFilters] = useState({
    industry: [],
    icpScore: [55, 95],
    companySize: [],
    companyRevenue: [],
    country: [],
    location: []
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  //below useEffect for getting user subscription details
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result?.status === 200) {
            setGetUserSubscriptionData(result?.data);
            setDownloadRemainingCounts(
              result.data.per_day_downlod_limit_remening
            );
          } else if (result?.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  const toggleColumnVisibility = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  // remove setState make it const if necessary
  const [totalPages, setTotalPages] = useState(abmData?.total_page);
  
  // Generate an array of page numbers based on the totalPages
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const dispatchReduxAction = (page) => {

    let payload = {
      ...state,
      page: page
    };
    if (isStaff) {
      payload = { ...payload, staff_id: userData.userInfo?.user_staff?.user };
    }
    setCurrentPage(page)
    // Dispatch your Redux action with payload
    dispatch(
      getAbmScore(payload, token, (result) => {
        if (result?.status === 200) {
          setTotalPages(result.total_page)
          setDataToRender(result.data)
          Setabmdata(result.data)
          setCurrentPage(result.current_page)
        } else if (result.status === 400) {

        }
      })
    );
  }


  const handlePageChange = (pageNumber) => {
    if (pageNumber > pageVisibility?.user_page_visibility) {
      if (pageVisibility.is_free_trial) {
        setDisabled(true)
      }
      setCurrentPage(pageNumber);
      return true
    }
    setDisabled(false)
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      dispatchReduxAction(pageNumber)
      const checkExistSelect = checkSelectAll.includes(pageNumber);
      setSelectAll(checkExistSelect);

    }
  };

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    // handlePageChange(1); // Reset to the first page when the search input changes
    filterData(input);
  };

  const handleExportModel = () => {
    if (downloadRemainingCounts < selectedRows.length) {
      setIsDialogOpen(true);
      return;
    }
    setOpenModal(true);
  };

  const handleClose = () => setIsDialogOpen(false);

  const filterData = (input) => {
    const lowercasedInput = input.toLowerCase();
    const filtered = icpData.filter((data) =>
      data.company_name.toLowerCase().includes(lowercasedInput) ||
      data.domain.toLowerCase().includes(lowercasedInput)
    );
    setDataToRender(filtered);
    setTotalRecords(filtered.length)
  };



  const handleSelectAll = () => {
    // Toggle the selectAll state
    setSelectAll((prevSelectAll) => !prevSelectAll);

    // Update the selectedRows based on the selectAll state
    const allRowIds = sortedData.map((data) => ({
      id: data.id,
      icp_score: data.icp_score, // Include icp_score along with id
    }));

    if (!selectAll) {
      // Add rows from the current page to selectedRows
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        ...allRowIds.filter(
          (row) => !prevSelectedRows.some((prevRow) => prevRow.id === row.id)
        ),
      ]);

      // Add the current page to checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) => [
        ...prevCheckSelectAll,
        currentPage,
      ]);
    } else {
      // Remove rows from the current page from selectedRows
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (row) => !allRowIds.some((newRow) => newRow.id === row.id)
        )
      );

      // Remove the current page from checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) =>
        prevCheckSelectAll.filter((page) => page !== currentPage)
      );
    }
  };

  const handleCheckboxChange = (id, icpScore) => {
    if (selectedRows.some((row) => row.id === id)) {
      // If the row is already selected, remove it from selectedRows
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.id !== id)
      );
      setSelectAll(false); // Uncheck the header checkbox if any individual checkbox is unchecked
    } else {
      // If the row is not selected, add it to selectedRows along with its icp_score
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        { id, icp_score: icpScore },
      ]);

      if (selectedRows.length + 1 === sortedData.length) {
        setSelectAll(true); // Check the header checkbox if all individual checkboxes are checked
      }
    }
  };
  const onClose = () => {
    setOpenModal(false);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: Array.isArray(prevFilters[filterType])
        ? prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter((item) => item !== value)
          : [...prevFilters[filterType], value]
        : value,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      industry: [],
      icpScore: [55, 95],
      companySize: [],
      companyRevenue: [],
      country: [],
      location: []
    });
    setSearchInput("");
    setSelectedRows([]);
    setSelectAll(false);
    setIcpData(abc);
    setDataToRender(abc);
    setTotalRecords(abmData?.total_count)

  };

  const handleApplyFilters = () => {
    let filtered = abc;
    // Filter by Main Industry
    if (
      filters.industry.length > 0 &&
      filters.industry[filters.industry.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.industry[filters.industry.length - 1].includes(data.industry_id)
      );
    }

    // Filter by ICP Score range
    filtered = filtered.filter(
      (data) =>
        data.icp_score >= filters.icpScore[0] &&
        data.icp_score <= filters.icpScore[1]
    );

    // Compare with the comp_size range
    if (
      filters.companySize.length > 0 &&
      filters.companySize[filters.companySize.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.companySize[filters.companySize.length - 1].includes(
          data.comp_size
        )
      );
    }

    // Compare with the Company Revenue range
    if (
      filters.companyRevenue.length > 0 &&
      filters.companyRevenue[filters.companyRevenue.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.companyRevenue[filters.companyRevenue.length - 1].includes(
          data.revenue
        )
      );
    }

    if (
      filters.location.length > 0 &&
      filters.location[filters.location.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.location[filters.location.length - 1].includes(
          data?.country
        )
      );
    }

    const paginatedData = filtered;
    setIcpData(filtered);
    setDataToRender(paginatedData);
    setTotalRecords(paginatedData.length)
  };
  const handleRangeChange = (type, values) => {
    setFilters({
      ...filters,
      [type]: values,
    });
  };
  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };
  //sorting function
  const sortedData = [...dataToRender].sort((a, b) => {
    const columnA = a[sortBy.column];
    const columnB = b[sortBy.column];

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });
  return (
    <>
      {openModal ? (
        <ExportFile
          showModal={openModal}
          onClose={onClose}
          icpData={icpData}
          selectedRows={selectedRows}
          type="ABM"
        />
      ) : null}
      <div className="pageHeadingBlock commonHeading">
        <ICPTitle title="Verify ABM Results" backTo="/abm-lal" />
        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}
      </div>
      <p style={{ textAlign: "center", fontSize: "15px" }}>
        Selected subcategory : {showProductSubcategory}
      </p>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper abmResults">
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        disabled={is_disabled}
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      {/* <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </form>
                  <div className="filtersBlock" style={{ pointerEvents: is_disabled ? 'none' : 'auto' }}>
                    <Filters
                      filters={filters}
                      setFilters={setFilters}
                      handleFilterChange={handleFilterChange}
                      handleResetFilters={handleResetFilters}
                      handleApplyFilters={handleApplyFilters}
                      handleRangeChange={handleRangeChange}
                      type="ABM"
                      icpData={abmdata}
                    />
                    <Columns
                      columnVisibility={columnVisibility}
                      toggleColumnVisibility={toggleColumnVisibility}
                      setColumnVisibility={setColumnVisibility}
                      type="ABM"
                    />
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <span className="pagination-page-block">
                      <strong>Total Records : {totalRecords}</strong>
                    </span>{" "}
                    <div className="d-flex align-items-center">
                      <span
                        style={{ marginLeft: "5rem" }}
                        className="page-span"
                      >
                        {" "}
                        Page :
                      </span>
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div className="prev paginationItem">
                        <Link to="" className="paginationLink" onClick={() => handlePageChange(currentPage - 1)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"

                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="next paginationItem">
                        <Link to="" className="paginationLink" onClick={() => handlePageChange(currentPage + 1)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"

                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FullScreen handle={handle}>
                <ICPResultTable
                  icpData={dataToRender}
                  columnVisibility={columnVisibility}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  handleSelectAll={handleSelectAll}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSort={handleSort}
                  sortedData={sortedData}
                  sortBy={sortBy}
                  type="ABM"
                  currentPage={currentPage}
                  getUserSubscriptionData={getUserSubscriptionData}
                />
              </FullScreen>
            </div>
            <div className="tableSelectionRecord">
              <div className="selectedItems">
                <h6>{`${selectedRows.length} Item${selectedRows.length !== 1 ? "s" : ""
                  } Selected`}</h6>

                <p>
                  <strong>Note:</strong> One credit will be deducted for each
                  entry exported from the ABM results.
                </p>
              </div>
              <div className="exportItems">
                <Link
                  to=""
                  className={`btn ${selectedRows.length === 0 ? "disabled" : ""
                    }`}
                  onClick={handleExportModel}
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M7.16675 14.1667L10.5001 17.5M10.5001 17.5L13.8334 14.1667M10.5001 17.5V10M17.1667 13.9524C18.1847 13.1117 18.8334 11.8399 18.8334 10.4167C18.8334 7.88536 16.7814 5.83333 14.2501 5.83333C14.068 5.83333 13.8976 5.73833 13.8052 5.58145C12.7185 3.73736 10.7121 2.5 8.41675 2.5C4.96497 2.5 2.16675 5.29822 2.16675 8.75C2.16675 10.4718 2.86295 12.0309 3.98921 13.1613"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg> */}
                  Export
                </Link>
              </div>
            </div>
            {isDialogOpen && (
              <SearchModel
                isDialogOpen={isDialogOpen}
                message="Your Per Day Download Limit Reached"
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ABMResult;
