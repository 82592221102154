import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { COMPANY_SIZE_MAP, REVENUE_MAP } from "./FormLables";

const PieProfileChart = ({ data, type }) => {
  const [chartData, setChartData] = useState([["Label", "Value"]]);

  //Added useEffect for adding animation -Rupali
  useEffect(() => {
    if (!data || !Object.keys(data).length) {
      setChartData([["Label", "Value"]]);
      return;
    }

    const mapToUse = type === "revenue" ? REVENUE_MAP : type === "emp_size" ? COMPANY_SIZE_MAP : null;

    let formattedLabels;
    if (mapToUse) {
      formattedLabels = Object.keys(mapToUse).map((key) => ({
        label: mapToUse[key],
        value: data[key] || 0,
      }));
    } else {
      formattedLabels = Object.keys(data).map((key) => ({
        label: key,
        value: data[key],
      }));
    }

    formattedLabels.sort((a, b) => b.value - a.value);
    // Slowly add data to the chartData
    const newChartData = [["Label", "Value"]];
    let index = 0;

    const interval = setInterval(() => {
      if (index < formattedLabels.length) {
        newChartData.push([
          formattedLabels[index].label,
          formattedLabels[index].value,
        ]);
        setChartData([...newChartData]); // Trigger re-render
        index++;
      } else {
        clearInterval(interval);
      }
    }, 150); // Adjust the interval time for speed

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [data, type]);

  return (
    <div
      style={{
        position: "relative",
        height: "250px",
        width: "100%",
        left: "0",
      }}
    >
      <Chart
        chartType="PieChart"
        width={"100%"}
        height={"250px"}
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          is3D: true,
          sliceVisibilityThreshold: 0,
          legend: {
            position: "left",
            width: "100%",
            alignment: "center",
            textStyle: {
              fontSize: 10,
            },
          },
         
          onHover: function(event, legendItem) {
            var me = this;
            var options = me.options || {};
            var hoverOptions = options.hover;
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var elements = ci.getDatasetMeta(index).data;
            ci.updateHoverStyle(elements, hoverOptions.mode, true)
            ci.render();  //    <<---- commit changes
          },          
          chartArea: {
            left: 0,
            width: "90%",
            height: "100%",
          },
          pieSliceText: "value",
          pieSliceTextStyle: {
            color: "white",
            fontSize: 12,
          },        
        }}
      />
    </div>
  );
};

export default PieProfileChart;