import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSavedSearches } from "../../../context/actions/User";
import { Skeleton } from 'primereact/skeleton';

const SaveSearch = ({ showAddProfileModal, showModal, onClose, patchfilterValue }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state?.user?.userInfo?.token);
    const userData = useSelector((state) => state?.user);
    const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
    const [isloading, setLoading] = useState(false)
    const [allSavedSeaches, setallSavedSeaches] = useState([]);

    useEffect(() => {
        const payload = {
            user_id: userId,
        };
        setLoading(true)
        dispatch(
            getAllSavedSearches(payload, (data) => {
                setallSavedSeaches(data.data);
                setLoading(false)
            })
        );
    }, []);

    // Helper function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Adding 'th', 'st', 'nd', 'rd' to the day
        const getOrdinalSuffix = (num) => {
            if (num > 3 && num < 21) return 'th'; // special case for 11th to 13th
            switch (num % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        return `${day}${getOrdinalSuffix(day)} ${month} at ${hours}:${minutes}`;
    };

    const applySearch = (filters) => {
        patchfilterValue(filters)

    }
    const LoadingRows = () => {
        const skeletonCount = 5;
        return (
            <>
                {Array.from({ length: skeletonCount }).map((_, index) => (
                    <tr key={index}>
                        <td><Skeleton width="30px" height="1.2rem" /></td> {/* For index number */}
                        <td><Skeleton width="100px" height="1.2rem" /></td> {/* For vais_filter_name */}
                        <td><Skeleton width="80px" height="1.2rem" /></td> {/* For updated_at date */}
                        <td><Skeleton width="50px" height="1.2rem" /></td> {/* For Apply button */}
                    </tr>
                ))}
            </>
        );
    };

    return (
        <>
            <Modal className="saveSearchModal" show={showModal} onHide={onClose} centered>
                <Modal.Body className="modal-body text-center saveFiltersModalBody">
                    <span className="closeModal" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" title="Close">
                            <path d="M7.00047 8.0543L1.92737 13.1274C1.78892 13.2658 1.61489 13.3367 1.40527 13.3399C1.19567 13.3431 1.01844 13.2723 0.873575 13.1274C0.728691 12.9825 0.65625 12.8069 0.65625 12.6005C0.65625 12.3941 0.728691 12.2184 0.873575 12.0736L5.94665 7.00047L0.873575 1.92737C0.735108 1.78892 0.664275 1.61489 0.661075 1.40527C0.657858 1.19567 0.728691 1.01844 0.873575 0.873575C1.01844 0.728691 1.19407 0.65625 1.40047 0.65625C1.60687 0.65625 1.78251 0.728691 1.92737 0.873575L7.00047 5.94665L12.0736 0.873575C12.212 0.735108 12.3861 0.664275 12.5957 0.661075C12.8053 0.657858 12.9825 0.728691 13.1274 0.873575C13.2723 1.01844 13.3447 1.19407 13.3447 1.40047C13.3447 1.60687 13.2723 1.78251 13.1274 1.92737L8.0543 7.00047L13.1274 12.0736C13.2658 12.212 13.3367 12.3861 13.3399 12.5957C13.3431 12.8053 13.2723 12.9825 13.1274 13.1274C12.9825 13.2723 12.8069 13.3447 12.6005 13.3447C12.3941 13.3447 12.2184 13.2723 12.0736 13.1274L7.00047 8.0543Z" fill="black" /></svg>
                    </span>
                    <h2>Saved Searches</h2>
                    <p>Apply from your Saved searches</p>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Search Name</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isloading && <LoadingRows />}
                                        {!isloading && allSavedSeaches.length > 0 ? (
                                            allSavedSeaches.map((search, index) => (
                                                <tr key={search.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{search?.vais_filter_name}</td>
                                                    <td>{formatDate(search.updated_at)}</td> {/* Format date here */}
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            className="btn btnPrimaryOutline applyBtnSmall btn-sm"
                                                            onClick={() => applySearch(search)}
                                                        >
                                                            Apply
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            !isloading && (
                                                <tr>
                                                    <td colSpan="4" className="text-center">No Data Found</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-group buttonGroup m-3 col-3 d-flex justify-content-end">
                                {/* <Button type="button" className="btn btnPrimaryOutline" onClick={onClose}>Cancel</Button> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
};
export default SaveSearch;
