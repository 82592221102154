import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./FindProspects.scss";
import { useNavigate } from "react-router-dom";
import excelIcon from "../../assets/images/csvIcon.png";
import { Formik, Form } from "formik";
import { findProspectValidation } from "../../utils/Validations/Validations";
import excelTemplate from "../../assets/alltemplates/abmtemplate.csv";
import { saveAs } from "file-saver";
import _ from "lodash"; // For debounce functionality

import {
  findProspectDetails,
  getGeoLocation,
  getPeospectDetails,
  getProspectJobTitle,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { capitalize } from "../../utils/Validations/ComonFunctions";
import Information from "../BuildICP/ICPResult/Information";
import InformationStaff from "../BuildICP/ICPResult/InformationStaff";
import SearchModel from "../BuildICP/Modals/ExportFile/SearchModel";
import FreeTrial from "./Modals/ExportProspectFile/FreeTrial";

import JobTitlesData from './JobTitlesData.json';
import TopBarProgress from "react-topbar-progress-indicator";


TopBarProgress.config({
  barColors: {
    "0": "#ff4500",
    "1.0": "#ff4500"
  },
  shadowBlur: 5
});

const FindProspects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const isSubscribed = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData
  );
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [getAllGeolocation, setGeoLocation] = useState([]);
  const [loading, setLoading] = useState(false);

  // const udesubscriptiondata = useSelector(
  //   (state) => state?.userSubscriptionData?.userSubscrptionData?.data
  // );
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const initialValues = {
    job_title: "",
    job_function: [],
    job_level: [],
    revenue: "",
    size: "",
    uploadCompanyList: null,
    location: [],
  };
  const customStyles = {
    option: (base, { isSelected }) => {
      return {
        ...base,
        color: isSelected ? "#414141" : "#414141",
        backgroundColor: isSelected ? "#fafafa" : "#ffffff",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      };
    },

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0",
      border: "none",
      borderBottom: "1px solid #E6E6E6",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
      "&:focus": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "6px 0",
      maxHeight:"100px",
      overflowY:"auto"
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#414141",
      },
    }),
    Input: (base) => ({
      ...base,
      padding: "0",
      margin: "0",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#414141",
      margin: "0",
    }),
  };
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  const [getCompanySize, setGetCompanySize] = useState([]);
  const [getRevenue, setGetRevenue] = useState([]);

  const [getJobFunction, setGetJobFunction] = useState([]);
  const [getJobTitle, setJobTitle] = useState([]);

  const [getJobLevel, setGetJobLevel] = useState([]);
  const [Isloading, SetIsloading] = useState("Fetching Data...");
  const [spinnerLoader, SetspinnerLoader] = useState(false);
  

  useEffect(() => {
    if (isSubscribed?.status === 404) {
      navigate("/subscription-plans");
      toast("Please Buy Subscription Package!", { autoClose: 1200 });
    }
  }, [isSubscribed]);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result?.status === 200) {
            setGetUserSubscriptionData(result?.data);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  // for data of proppect details
  useEffect(() => {
    dispatch(
      getPeospectDetails(token, (result) => {
        // Adding "Select All" option for revenue
        const convertedRevenue = [
          { value: "selectAll", label: "Select All" },
          ...result.data.revenue.map((item) => ({
            value: item.value,
            label: item.revenue_range,
          })),
        ];

        // Adding "Select All" option for job function
        const convertedJobFunction = [
          { value: "selectAll", label: "Select All" },
          ...result.job_function.map((item) => ({
            value: item.value,
            label: item.job_function,
          })),
        ];

        // Adding "Select All" option for job level
        const convertedJobLevel = [
          { value: "selectAll", label: "Select All" },
          ...result.job_level.map((item) => ({
            value: item.value,
            label: item.job_level,
          })),
        ];

        setGetRevenue(convertedRevenue);
        setGetJobFunction(convertedJobFunction);
        setGetJobLevel(convertedJobLevel);
      })
    );
  }, []);

  //Added by Rupali

  useEffect(() => {
    SetspinnerLoader(true);
    dispatch(getProspectJobTitle(token, "", (response) => {
        const jobTitle = [
            // { value: "selectAll", label: "Select All" },
            ...response.map((item) => ({
                value: item,
                label: item,
            })),
        ];

        setJobTitle((prev) => {
            const updatedTitles = [
                ...prev,
                ...jobTitle
            ];
            // Use a Set to filter out duplicates based on the 'value' property
            const uniqueTitles = Array.from(new Set(updatedTitles.map(item => item.value)))
                .map(value => updatedTitles.find(item => item.value === value));

            return uniqueTitles;
        });
        SetspinnerLoader(false);
        SetIsloading("Select");
    }));
}, []);

// Debounce to avoid calling API too frequently
const handleInputChange = _.debounce((inputValue) => {
    if (inputValue.length >= 3) {
        SetspinnerLoader(true);
        dispatch(getProspectJobTitle(token, inputValue, (response) => {
            const jobTitle = [
                // { value: "selectAll", label: "Select All" },
                ...response.map((item) => ({
                    value: item,
                    label: item,
                })),
            ];

            setJobTitle((prev) => {
                const updatedTitles = [
                    ...prev,
                    ...jobTitle
                ];
                // Use a Set to filter out duplicates based on the 'value' property
                const uniqueTitles = Array.from(new Set(updatedTitles.map(item => item.value)))
                    .map(value => updatedTitles.find(item => item.value === value));

                return uniqueTitles;
            });
            SetspinnerLoader(false);
        }));
    }
}, 300);


  // const downloadExcelTemplate = () => {
  //   // Create column names
  //   const excelContent =
  //     "Company Name,Company Size,Industry,Website,Domain,Country,Revenue,Linkdin Link,Phone Number,Address,City,State,Post Code,NAICS Code";

  //   // Create a Blob with Excel content
  //   const blob = new Blob([excelContent], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   // Create an anchor element to trigger the download
  //   const a = document.createElement("a");
  //   a.href = window.URL.createObjectURL(blob);
  //   a.download = "template.xlsx"; // File name
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };

  useEffect(() => {
    dispatch(
      getGeoLocation(token, (result) => {
        const convertedData = [
          { value: "selectAll", label: "Select All" },
          ...result.map((item) => ({
            value: item.country,
            label: item.country,
          })),
        ];
        setGeoLocation(convertedData);
      })
    );
  }, []);

  // Function to handle download button click
  const downloadTemplate = (templateFileName) => {
    // Construct the URL for the Excel template file
    const templateUrl = excelTemplate;

    // Use file-saver library to trigger the download
    saveAs(templateUrl, templateFileName);
  };

  // //parse function for abm content for both csv and XLSX
  // const parseCSV = (csvContent, formValues) => {
  //   Papa.parse(csvContent, {
  //     complete: (result) => {
  //       const domainNames = result.data.map((row) => row.Domain);

  //       dispatchReduxAction(formValues, domainNames);
  //     },
  //     header: true,
  //     skipEmptyLines: true,
  //   });
  // };

  // const parseXLSX = (xlsxContent, formValues) => {
  //   const workbook = XLSX.read(xlsxContent, { type: "binary" });
  //   const sheetName = workbook.SheetNames[0];
  //   const sheet = workbook.Sheets[sheetName];
  //   const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  //   const domainIndex = parsedData[0].indexOf("Domain");
  //   const domainNames = parsedData.slice(1).map((row) => row[domainIndex]);

  //   dispatchReduxAction(formValues, domainNames);
  // };

  //parse function for abm content for both csv and XLSX
  const parseCSV = (csvContent, formValues) => {
    Papa.parse(csvContent, {
      complete: (result) => {
        const headers = result.meta.fields;
        if (!headers.includes("Domain")) {
          toast("Domain column is missing in the file!", { autoClose: 1000 });
          return;
        }
        const domainNames = result.data.map((row) => row["Domain"]); // Use index 8 for the Domain column
        if (domainNames === undefined)
          toast("provided file is empty, please use prospect template !");
        else {
          if (domainNames.length === 0) {
            toast("Please Include Domain Name In File !", { autoClose: 1000 });
          } else {
            dispatchReduxAction(formValues, domainNames);
          }
        }
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const parseXLSX = (xlsxContent, formValues) => {
    const workbook = XLSX.read(xlsxContent, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const domainIndex = parsedData[0]?.indexOf("Domain");
    if (domainIndex === undefined)
      toast("provided file is empty, please use prospect template !");
    else {
      const domainNames = parsedData
        .slice(1)
        .map((row) => row[domainIndex])
        .filter((domain) => domain); // Use index obtained from indexOf
      if (domainNames.length === 0) {
        toast("Please Include Domain Name In File !", { autoClose: 1000 });
      } else {
        dispatchReduxAction(formValues, domainNames);
      }
    }
  };

  //dispatch redux action for verify abm
  const dispatchReduxAction = (formValues, domainNames) => {
    let payload = {
      job_title: capitalize(formValues.job_title),
      job_function: formValues.job_function,
      job_level: formValues.job_level,
      revenue: formValues.revenue,
      size: formValues.size,
      domainNames: domainNames,
      user_id: userId,
      location: formValues.location,
      
    };
    setLoading(true); 
    if (isStaff) {
      payload = { ...payload, staff_id: userData.userInfo?.user_staff?.user };
    }
    dispatch(
      findProspectDetails({...payload,is_credit:true}, token, (result) => {
        if (result?.status === 200) {
          setLoading(false);
          navigate("/find-prospects/prospect-details-result",{state: payload});
          localStorage.setItem(
            "location",
            "/find-prospects/prospect-details-result"
          );
        } else if (result.status === 400) {
          setIsDialogOpen(true);
        }
      })
    );
  };
  const handleClose = () => setIsDialogOpen(false);

  const handleSubmit = (values) => {
    // Check if uploadCompanyList is present
    if (values.uploadCompanyList) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Parse the uploaded file content based on file type
        const fileType = values.uploadCompanyList.name
          .split(".")
          .pop()
          .toLowerCase();
        if (fileType === "csv") {
          parseCSV(e.target.result, values);
        } else if (fileType === "xlsx") {
          parseXLSX(e.target.result, values);
        } else {
          toast.error("Unsupported file type");
        }
      };
      reader.readAsBinaryString(values.uploadCompanyList);
    }
  };
  
  return (
    <>

      {/* Pregress bar */}
      {loading &&                   
            <>                
              <TopBarProgress />
            </>
        }
      {/* Pregress bar END */}

      <div className="pageHeadingBlock commonHeading ">
        <div className="pageTitle">
          <h3>Find Prospects</h3>
        </div>
        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}{" "}
      </div>
      {isSubscribed?.data?.is_free_trial && <FreeTrial/>}
     { !isSubscribed?.data?.is_free_trial &&<div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper FindProspects">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={findProspectValidation}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  touched,
                  errors,
                }) => (
                  <Form className="FindProspectusForm">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="icpForm">
                          <div className="form-group">
                            <label>
                              Upload Company Lists{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <div className="uploadedFile">
                              <div className="fileDetilsItem">
                                <p>
                                  <img src={excelIcon} alt=""/>
                                  Prospect Template
                                </p>
                                <div className="downloadFile">
                                  <button
                                    type="button"
                                    className="btn btnPrimaryOutline"
                                    onClick={() =>
                                      downloadTemplate("Prospect template.csv")
                                    }
                                  >
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div /* className="uploadDocuments" */
                              className={`uploadDocuments ${
                                touched.uploadCompanyList &&
                                errors.uploadCompanyList
                                  ? "box"
                                  : ""
                              }`}
                            >
                              <input
                                className="uploadDocInput"
                                id="userImgUploadInput"
                                type="file"
                                accept=".xlsx, .csv"
                                onChange={(e) =>
                                  setFieldValue(
                                    "uploadCompanyList",
                                    e.currentTarget.files[0]
                                  )
                                }
                              />

                              <div className="uploadDocContent">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="49"
                                  height="34"
                                  viewBox="0 0 49 34"
                                  fill="none"
                                >
                                  <path
                                    d="M39.5063 12.9996C38.8205 9.52414 36.9493 6.39456 34.2123 4.14543C31.4754 1.8963 28.0425 0.667168 24.5 0.667969C18.5996 0.667969 13.475 4.0163 10.9229 8.9163C7.92214 9.24058 5.14706 10.6624 3.13087 12.9084C1.11469 15.1545 -0.000350558 18.0664 8.26727e-08 21.0846C8.26727e-08 27.8426 5.49208 33.3346 12.25 33.3346H38.7917C44.4267 33.3346 49 28.7613 49 23.1263C49 17.7363 44.8146 13.3671 39.5063 12.9996ZM28.5833 19.043V27.2096H20.4167V19.043H14.2917L23.7854 9.54922C24.1938 9.14089 24.8267 9.14089 25.235 9.54922L34.7083 19.043H28.5833Z"
                                    fill="#414141"
                                    fillOpacity="0.2"
                                  />
                                </svg>

                                {values?.uploadCompanyList?.name ? (
                                  <p>{values?.uploadCompanyList?.name}</p>
                                ) : (
                                  <p className="m-0">
                                    Select/Drop File to upload
                                    <span className="m-0">
                                      Must be .xlsx / .csv file Use the sample
                                      template.
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>

                            {touched.uploadCompanyList &&
                              errors.uploadCompanyList && (
                                <div className="error-message">
                                  {errors.uploadCompanyList}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="icpForm"> 
                          <div className="form-group" style={{display: "flex"}}>
                            
                            <div style={{width:"100%"}} >
                            <label>
                              Job Title
                            </label>
                            {/* <Select
                              options={JobTitlesData.items.map((ele)=>({
                                "value":ele,
                                "label":ele
                                      
                              }) )}
                              closeMenuOnSelect = {false}
                              styles={customStyles}
                              className={`selectBox ${touched.job_title && errors.job_title ? "error-input" : ""}`}
                              // placeholder={Isloading}
                              name="job_title"
                              // value={combinedOptions.filter(option => values.job_title.includes(option.value))}
                              onChange={(selectedOptions) => {
                                if (selectedOptions.length === combinedOptions.length) {
                                  // If all options are selected, deselect all
                                  setFieldValue("job_title", []);
                                } else {
                                  // Normal selection
                                  setFieldValue(
                                    "job_title",
                                    selectedOptions.map(option => option.value)
                                  );
                                }
                              }}
                              // onInputChange={handleInputChange}
                              onBlur={handleBlur}
                              isMulti
                            /> */}
                            
                            <Select
                              options={JobTitlesData.items.map((ele)=>({
                                "value":ele,
                                "label":ele
                                      
                              }) )}
                              styles={customStyles}
                              closeMenuOnSelect = {false}
                              className={`selectBox ${
                                touched.job_title && errors.job_title
                                  ? "error-input"
                                  : ""
                              }`}
                              placeholder={Isloading}
                              name="job_title"
                              // value={getJobTitle.filter((option) =>
                              //   values.job_title.includes(option.value)
                              // )}
                              onChange={(selectedOptions) => {
                                if (selectedOptions.length === getJobTitle.length) {
                                  // If all options are selected, deselect all
                                  setFieldValue("job_title", []);
                                }
                                else {
                                  // Normal selection
                                  setFieldValue(
                                    "job_title",
                                    selectedOptions.map(
                                      (option) => option.value
                                    )
                                  );
                                }
                              }}
                              onInputChange={handleInputChange} 
                              onBlur={handleBlur}
                              isMulti
                            />
                            {touched.job_title && errors.job_title && (
                              <div className="error-message">
                                {errors.job_title}
                              </div>
                            )}
                            </div>
                            { spinnerLoader && <div id="showSpinner" className="" style={{width:"5%"}}><div className="spinner"></div></div>
                            }
                          </div>


                          <div className="form-group">
                            <label>
                              Job Function{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Select
                              options={getJobFunction}
                              styles={customStyles}
                              className={`selectBox ${
                                touched.job_function && errors.job_function
                                  ? "error-input"
                                  : ""
                              }`}
                              placeholder="Select"
                              name="job_function"
                              value={getJobFunction.filter((option) =>
                                values.job_function.includes(option.value)
                              )}
                              onChange={(selectedOptions) => {
                                // Check if "Select All" is selected
                                if (
                                  selectedOptions.some(
                                    (option) => option.value === "selectAll"
                                  )
                                ) {
                                  if (
                                    selectedOptions.length ===
                                    getJobFunction.length
                                  ) {
                                    // If all options are selected, deselect all
                                    setFieldValue("job_function", []);
                                  } else {
                                    // Select all options except "Select All"
                                    setFieldValue(
                                      "job_function",
                                      getJobFunction
                                        .filter(
                                          (option) =>
                                            option.value !== "selectAll"
                                        )
                                        .map((option) => option.value)
                                    );
                                  }
                                } else {
                                  // Normal selection
                                  setFieldValue(
                                    "job_function",
                                    selectedOptions.map(
                                      (option) => option.value
                                    )
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              isMulti
                            />
                            {touched.job_function && errors.job_function && (
                              <div className="error-message">
                                {errors.job_function}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label>
                              Job Level <span className="required">&#42;</span>
                            </label>{" "}
                            <Select
                              options={getJobLevel}
                              styles={customStyles}
                              className={`selectBox ${
                                touched.job_level && errors.job_level
                                  ? "error-input"
                                  : ""
                              }`}
                              placeholder="Select"
                              name="job_level"
                              value={getJobLevel.filter((option) =>
                                values.job_level.includes(option.value)
                              )}
                              onChange={(selectedOptions) => {
                                // Check if "Select All" is selected
                                if (
                                  selectedOptions.some(
                                    (option) => option.value === "selectAll"
                                  )
                                ) {
                                  if (
                                    selectedOptions.length ===
                                    getJobLevel.length
                                  ) {
                                    // If all options are selected, deselect all
                                    setFieldValue("job_level", []);
                                  } else {
                                    // Select all options except "Select All"
                                    setFieldValue(
                                      "job_level",
                                      getJobLevel
                                        .filter(
                                          (option) =>
                                            option.value !== "selectAll"
                                        )
                                        .map((option) => option.value)
                                    );
                                  }
                                } else {
                                  setFieldValue(
                                    "job_level",
                                    selectedOptions.map(
                                      (option) => option.value
                                    )
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              isMulti
                            />
                            {touched.job_level && errors.job_level && (
                              <div className="error-message">
                                {errors.job_level}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label>
                              Geolocation{" "}
                              <span className="required">&#42;</span>
                            </label>
                            <Select
                              options={getAllGeolocation}
                              styles={customStyles}
                              placeholder="Select"
                              className="selectBox"
                              name="location"
                              value={getAllGeolocation.filter((option) =>
                                values.location.includes(option.value)
                              )}
                              onChange={(selectedOptions) => {
                                if (
                                  selectedOptions.some(
                                    (option) => option.value === "selectAll"
                                  )
                                ) {
                                  if (
                                    selectedOptions.length ===
                                    getAllGeolocation.length
                                  ) {
                                    // If all options are selected, deselect all
                                    setFieldValue("location", []);
                                  } else {
                                    // Select all options except "Select All"
                                    setFieldValue(
                                      "location",
                                      getAllGeolocation
                                        .filter(
                                          (option) =>
                                            option.value !== "selectAll"
                                        )
                                        .map((option) => option.value)
                                    );
                                  }
                                } else {
                                  // Normal selection
                                  setFieldValue(
                                    "location",
                                    selectedOptions
                                      ? selectedOptions.map(
                                          (option) => option.value
                                        )
                                      : []
                                  );
                                }
                              }}
                              isMulti
                              onBlur={handleBlur}
                            />
                            {touched.location && errors.location && (
                              <div className="error-message">
                                {errors.location}
                              </div>
                            )}
                            {/* <span className="passwordHint">
                              For Product Category select
                              <strong> My Product Subcategory</strong>
                            </span> */}
                          </div>
                          {/* <div className="form-group">
                            <label>Company Revenue</label>
                            <Select
                              options={getRevenue}
                              styles={customStyles}
                              className="selectBox"
                              placeholder="Select"
                              name="revenue"
                              value={getRevenue.find(
                                (option) => option.value === values.revenue
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue("revenue", selectedOption.value)
                              }
                              onBlur={handleBlur}
                            />
                          </div> */}
                          {/* <div className="form-group">
                            <label>Company Size</label>
                            <Select
                              options={getCompanySize}
                              styles={customStyles}
                              className="selectBox"
                              placeholder="Select"
                              name="size"
                              value={getCompanySize.find(
                                (option) => option.value === values.size
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue("size", selectedOption.value)
                              }
                              onBlur={handleBlur}
                            />
                          </div> */}
                          <div className="form-group formSubmit m-0">
                            <button
                              type="submit"
                              className="btn btnPrimaryOutline"
                            >
                              Find Prospects
                            </button>
                            <p className="icpNote">
                              <strong>Note: </strong> Each 'Find Prospect'
                              action deducts one search from your available
                              credits.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {isDialogOpen && (
            <SearchModel
              isDialogOpen={isDialogOpen}
              message="Unable to search. Search Limit Reached!!! You don't have any credit remaining."
              handleClose={handleClose}
            />
          )}
        </div>
      </div>}
    </>
  );
};

export default FindProspects;
