import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import ICPScoreImg from "../../../assets/images/ICPScore.png";
import "./ICPScore.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CChart } from "@coreui/react-chartjs";
import "@coreui/coreui/dist/css/coreui.min.css";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Information from "../ICPResult/Information";
import {
  getIcpScore,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../../context/actions/User";
import InformationStaff from "../ICPResult/InformationStaff";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import SearchModel from "../Modals/ExportFile/SearchModel";
ChartJS.register(ArcElement, Tooltip, Legend);

const ICPScore = () => {
  const navigate = useNavigate();
  const icpData = useSelector((state) => state?.icpScoreResult?.icpScore?.data);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [randomPoint, setRandomPoint] = useState("");
  const [randomMessage, setRandomMessage] = useState("");
  const [loading, setLoading] = useState(false);

  
  // For Randomized the loading notes
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * points.length);
    setRandomPoint(points[randomIndex]);
  }, []);
  // For Randomized the loading notes END

  const points = [
    "What is VAIS?",
    "We do not charge for domains that do not exist in our database for ABM & LAL.",
    "Choose Intent Topics relevant to your sub-product categories.",
    "If you do not find your product sub-category, you can raise a new ticket.",
    "You can buy additional credits anytime during the validity of your base package.",
    "Remember, there are three types of credits: Search Credit, Download Credit, and Prospect Credit.",
    "Validate and verify your ABM strategies with Valasys AI for precise targeting.",
    "Easily generate Lookalike Audiences to expand your reach and find new opportunities.",
    "Access detailed prospect information to enhance your outreach and engagement efforts.",
    "Utilize our advanced filtering options to pinpoint the exact leads you need.",
    "Leverage weekly Intent Signals to stay ahead of market trends and identify potential buyers.",
    "Optimize your campaigns by integrating VAIS scores with your marketing automation tools.",
    "Explore our extensive knowledge base to get the most out of Valasys AI.",
    "Keep track of your usage and manage your credits effectively for maximum ROI.",
    "Benefit from our robust industry mapping to align your products with the right sectors.",
    "Generate actionable insights from our 600k+ product-industry relevance mappings.",
    "Refine your Intent Topic selection to align perfectly with your sub-product goals.",
    "Tap into 16,000+ Intent Topics to sharpen your focus and capture the right leads.",
    "Maximize your ABM results by combining VAIS scores with Intent Signals.",
    "You can easily purchase additional credits anytime to continue driving your campaigns.",
    "Enhance your targeting precision with data-driven insights from Valasys AI.",
    "The VAIS score is generated using 7+ factors.",
    "The FAQ section contains a knowledge base to help you understand the platform.",
    "We have reviewed over 45,000 products and classified them into over 290 Subcategories.",
    "There are over 16,000 Intent topics to choose from.",
    "Intent Signals are generated weekly for over 4M+ domains.",
    "We have created over 600k product-industry relevance mappings.",
    "Whenever in doubt, follow our VAIS guidelines.",
    "Did you know, VAIS+Intent together can help you in your Ads campaign as DMP?",
  ];

  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * points.length);
    return points[randomIndex];
  };

  useEffect(() => {
    let interval;

    if (loading) {
      // Set the initial message
      setRandomMessage(getRandomMessage());

      // Change the message every 5 seconds
      interval = setInterval(() => {
        setRandomMessage(getRandomMessage());
      }, 4000);
    } else {
      // Clear interval when loading is false
      clearInterval(interval);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [loading]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const { state } = useLocation();
  // const [percentageData, setPercentageData] = useState([]);
  const [graphData, setGraphData] = useState({
    count30_40: 0,
    count40_50: 0,
    count50_60: 0,
    count60_70: 0,
    count70_80: 0,
    count80_90: 0,
    count90_100: 0,
    total: 0,
  });
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  const handleClose = () => setIsDialogOpen(false);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  const outputData = {
    companies_data: icpData?.map((item) => ({
      company_name: item?.company_name,
      icp_score: item?.icp_score,
    })),
  };

  function icp_split(outputData) {
    let total_count = outputData?.length;
    let count_data = {
      count30_40: 0,
      count40_50: 0,
      count50_60: 0,
      count60_70: 0,
      count70_80: 0,
      count80_90: 0,
      count90_100: 0,
      total: total_count,
    };

    for (let i = 0; i < total_count; i++) {
      let company = outputData[i];

      if (90 < company.icp_score && company.icp_score <= 100) {
        count_data["count90_100"] += 1;
      }
      if (80 < company.icp_score && company.icp_score <= 90) {
        count_data["count80_90"] += 1;
      }
      if (70 < company.icp_score && company.icp_score <= 80) {
        count_data["count70_80"] += 1;
      }
      if (60 < company.icp_score && company.icp_score <= 70) {
        count_data["count60_70"] += 1;
      }
      if (50 < company.icp_score && company.icp_score <= 60) {
        count_data["count50_60"] += 1;
      }

      if (40 < company.icp_score && company.icp_score <= 50) {
        count_data["count40_50"] += 1;
      }
      if (30 < company.icp_score && company.icp_score <= 40) {
        count_data["count30_40"] += 1;
      }
    }
    return count_data;
  }

  useEffect(() => {
    // Check if icpData is not null before calculating percentages
    if (icpData) {
      const result = icp_split(outputData?.companies_data);

      // setPercentageData(calculatePercentage(result));
      setGraphData(result);
    }
  }, [icpData]); // Add icpData as a dependency

  const chartOptions = {
    chart: {
      type: "donut",
      size: "30%",
      width: isMobile ? "100%" : 380,
    },
    stroke: {
      width: 1,
    },
    labels: ["60-70", "70-80", "80-90", "90-100"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="tooltip-content">
            <span><strong><i class="fas fa-building"></i>${series[seriesIndex]} Companies </strong></span><br/>
            <span>ICP Split: ${w.globals.labels[seriesIndex]}</span>
          </div>`;
      },
    },
    legend: {
      offsetY: isMobile ? 0 : 70,
      position: isMobile ? "bottom" : "right",
      formatter: function (val, opts) {
        return (
          val +
          " : " +
          `<span><strong>${
            opts.w.globals.series[opts.seriesIndex]
          }</strong></span>`
        );
      },
    },
    colors: ["#F5A243", "#F7B066", "#F9C48C", "#FDEBD9"],
  };

  const chartSeries = [
    graphData.count60_70,
    graphData.count70_80,
    graphData.count80_90,
    graphData.count90_100,
  ];

  const handleRedirect = (data) => {
    setLoading(true);
       
    let payload = { ...data, is_credit: true };
    dispatch(
      getIcpScore(payload, token, (result) => {
      
          if (result?.status === 200) {
            navigate("/build-your-vais/vais-result", { state: data });
            
            localStorage.setItem("location", "/build-your-vais/vais-result");
          } else if (result?.status === 400) {
            setIsDialogOpen(true);
          }
      
      })
    );
  };

  const vaisContent = (
    <>
      <h4>
        What is VAIS?{" "}<span className="limit-remaining">
                      {getUserSubscriptionData.per_day_data_download_credit_used +"/"+getUserSubscriptionData?.per_day_data_download_limit + " Utilized Per Day Download"}
                    </span>
      </h4>
      <p>
        Valasys AI Score (VAIS) is a propensity score that measures how closely
        a company aligns with your product. The higher the score, the more ideal
        the company is for your product.
      </p>
    </>
  );

  return (
    <>
      <div className="pageHeadingBlock commonHeading">
        <div className="pageTitle">
          <h3>
            <Link to="/build-your-vais" className="backToPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                  fill="#414141"
                />
              </svg>
            </Link>{" "}
            VAIS Score
          </h3>
        </div>

        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper ICPScore">
              <div className="row align-items-center">
                {/* <div className="col-12 col-lg-5">
                    <div className="ICPScoreGraph">
                      {" "}
                      <ReactApexChart
                        options={chartOptions}
                        series={chartSeries}
                        type="donut"
                        height={350}
                      />
                    </div>
                  </div> */}
                <div className="col-12 col-lg-1"></div>
                <div className="col-12 col-lg-12">
                  <div className="ICPScoreGraphContent">
                    {randomPoint === "What is VAIS?" ? (
                      vaisContent
                    ) : (
                      <h4>{randomPoint} <span className="limit-remaining">
                      {getUserSubscriptionData.per_day_data_download_credit_used +"/"+getUserSubscriptionData?.per_day_data_download_limit + " Utilized Per Day Download"}
                    </span></h4>
                    )}
                    
                  {loading && <>
                  <center>
                    <h3
                  
                     style={{
                        zIndex : "9999",
                        margin :"auto !important", 
                        textAlign : "center", 
                        color: "white" ,  
                        position: "fixed", 
                        top: "30%", 
                        left: "50%",
                        transform: "translateX(-50%)",
                       }} 
                      // className="highlight-text"
                        >
                        {randomMessage}
                        </h3>
                        </center>
                        
                        </>}
                 
                    <div className="d-flex justify-content-center">
                      <button
                        className="showResult btn btnPrimaryOutline"
                        onClick={() => handleRedirect(state)}
                      >
                        Show Results
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>     
      {isDialogOpen && (
        <SearchModel
          isDialogOpen={isDialogOpen}
          message="Unable to search. Search Limit Reached!!! You don't have any credit remaining."
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default ICPScore;
