import "rc-slider/assets/index.css";
import React, { useCallback, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import {
    CampainsDelete,
    getAllStandardUsers,
    getCampains,
    getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { toast } from "react-toastify";
import Information from "../BuildICP/ICPResult/Information";
import "./campiagn.scss"
import { debounce } from "lodash";
import CampaignsInformation from "./CampaignInformation";
import { formatDate, formatTime } from "../../utils/Validations/ComonFunctions";
import { handleCreateConfirmation } from "../../components/Modal/ConfirmBox";
import { FirstLetterCapital } from "../../utils/constants";
export default function Index() {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user);
    const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
    const token = useSelector((state) => state?.user?.userInfo?.token);
    const [searchInput, setSearchInput] = useState("");
    const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
    const [campaign, setCampaign] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {
            const payload = {
                user_id: userId,
            };
            dispatch(
                getUserSubscriptionPlanDetails(payload, token, (result) => {
                    if (result.status === 200) {
                        setGetUserSubscriptionData(result?.data);
                    } else if (result.status === 404) {
                        navigate("/subscription-plans");
                        toast("Please Buy Subscription Package!", { autoClose: 1200 });
                    }
                })
            );
        }
    }, []);

    const fetchCampaign = (search) => {
        dispatch(
            getCampains({ user_id: userId, search }, token, (result) => {
                setCampaign(result.result)
            })
        );
    }

    useEffect(() => {
        fetchCampaign("")
    }, [])

    const debouncedSearch = useCallback(
        debounce((input) => {
            fetchCampaign(input)
            console.log("serch", input)
        }, 1000),
        [])
    const handleSearchInputChange = (e) => {
        const input = e.target.value;
        debouncedSearch(input);
    };

    const formatStatus = {
        "complete": "Complete",
        "in_progress": "In progress",
        "open": "Open",
        "Accepted": "Accepted"
    }

    const handleDelete = (id) => {
        handleCreateConfirmation(
            id,
            (id) => {
                console.log("Delete user with ID:", id);
                dispatch(
                    CampainsDelete(id, token, (result) => {
                        if (result.status === 200) {
                            fetchCampaign("")
                        }
                    })
                );
            },
            () => {
                return false;
            }
        );
    };

    const handelDownloadFile = (file) => {
        const link = document.createElement('a');
        link.href = file;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    return (
        <>
            <div className="pageHeadingBlock commonHeading">
                <ICPTitle title="My Campiagn" backTo="/dashboard" />
                <CampaignsInformation getUserSubscriptionData={getUserSubscriptionData} />
            </div>
            <div className="pageContentBlock">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="contentWrapper myDownloadList">
                            <div className="tableHeadingBlock">
                                <div className="leftContent">
                                    <form className="searchForm">
                                        <div className="form-group m-0">
                                            <input
                                                type="text"
                                                name="search"
                                                className="form-control"
                                                placeholder="Search"
                                                // value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />

                                        </div>
                                    </form>
                                </div>

                                <div className="raiseTicket">
                                    <Link to="/build-campaign" className="btn">
                                        Request Campaign
                                    </Link>
                                </div>
                                <Table>
                                    <thead
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                        }}
                                    >
                                        <tr>
                                            <th>Campaign Name</th>
                                            <th>Assign Team member</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th >Download</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {campaign.length > 0 ? (
                                            campaign?.map((data) => (
                                                <tr key={data.id}>
                                                    <td>{FirstLetterCapital(data.campaign_name)}</td>
                                                    <td>{data.team_member_name ? FirstLetterCapital(data.team_member_name) : "Not assigned yet"}</td>
                                                    <td>{formatDate(data.created_at)} - {formatTime(data.created_at)}</td>
                                                    <td >
                                                        <span className={`status-signal-score ${data.status}`}>
                                                            {formatStatus[data?.status] || "-"}</span>
                                                    </td>
                                                    <td>
                                                        {data?.result_file && (
                                                            <svg
                                                                fill="#000000"
                                                                width="20px"
                                                                height="20px"
                                                                viewBox="0 0 32 32"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                onClick={() => handelDownloadFile(data?.result_file)}
                                                                style={{
                                                                    display: 'block',
                                                                    margin: '0 ,0,0,0',
                                                                    // verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                <g
                                                                    id="SVGRepo_tracerCarrier"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                                                </g>
                                                            </svg>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {(data.status === 'complete' || data.status == "Accepted") ? <div
                                                            className="icon-wrapper-campaign"
                                                            // title="View"
                                                            onClick={() => navigate(`/build-my-campaign/${data?.id}`)}
                                                        >
                                                            {/* <i class="fa fa-eye" aria-hidden="true"></i> */}
                                                            View
                                                        </div> : <div
                                                            style={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: '55px',
                                                                height: '27px',
                                                                marginRight: '10px',
                                                            }
                                                            }
                                                        >
                                                            --

                                                        </div>}
                                                        <div
                                                            className="icon-wrapper-campaign"
                                                            title="Delete"
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            Delete
                                                            {/* <i className="fa fa-trash"></i> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={7}>
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
