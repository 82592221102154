import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import slideImage from "../../../assets/images/slide-image.jpg";
import Logo from "../../../assets/images/logo.png";
import { Formik, Form, ErrorMessage } from "formik";
import { loginValidation } from "../../../utils/Validations/Validations";
import { useDispatch, useSelector } from "react-redux";
import { linkdin, user_signin } from "../../../context/actions/User";
import ReCAPTCHA from "react-google-recaptcha";
import "./login.scss";
import { hostURl } from "../../../config/http";
import { SITE_KEY } from "../../../utils/constants";
// import AuthRedirectHome from "../AuthRedirectHome";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [captchaValue, setCaptchaValue] = useState(null);
  const initialValues = {
    username: "",
    password: "",
  };
  useEffect(() => {
    // Extracting access code from URL parameters when redirected from LinkedIn
    // const params = new URLSearchParams(location.search);
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      // If access code exists, dispatch action to send it to the backend
      dispatch(
        linkdin(code, (res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          }
        })
      );
    }
  }, [window.location.search]); // Trigger useEffect when location.search changes
  // const sendAccessCodeToBackend = (code) => {
  //   // Dispatch action to send access code to backend
  //   // You need to implement this action in your Redux context
  //   // Example: dispatch(sendAccessCodeAction(code));
  // };

  // Show Passwords Functionality
      const [showPassword, setShowPassword] = useState(false);
    
      // Toggle password visibility
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      }
  // Show Passwords Functionality

  const onSubmit = (values) => {
    dispatch(
      user_signin(values, (res) => {
        if (res.status === 200) {
          navigate("/dashboard");
        }
      })
    );
  };

  // to prevent going to login page when user is logged in
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isActive = useSelector((state) => state.user.userInfo?.is_active);
  useEffect(() => {
    if (isLoggedIn && isActive) {
      navigate("/dashboard");
    }
  }, []);

  const handleCaptchaChange = (value, setFieldValue) => {
    setFieldValue("captcha", value);
    setCaptchaValue(value);
  };

  const clientId = process.env.REACT_APP_CLIENT_ID
  // const redirectUri = encodeURIComponent(`${window.location.origin}/login`);
  const redirectUri = `${hostURl}/login`;

  const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid,profile,email`;

  return (
    <>
      <div className="loginPage authPages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="contentSlider">
                <div className="imageBlock">
                  <img src={slideImage} className="slideImage" alt="" />
                </div>
                <div className="contentBlock text-center">
                  <h2>Welcome!</h2>
                  <p>
                    Elevate your business insights with precision. Log in to
                    discover and refine your Ideal Customer Profiles
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formWrapper">
                <div className="siteLogo" style={{ marginBottom: "0px" }}>
                  <img src={Logo} className="logoImage" alt="" />
                </div>
                <h2>Login</h2>

                <Formik
                  initialValues={initialValues}
                  validationSchema={loginValidation}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isValid,
                    touched,
                    errors,
                  }) => (
                    <Form className="loginForm authForm">
                      <div className="row">
                        <div className="form-group col-12">
                          <label>
                            Email <span className="required">&#42;</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-12 mb-3" style={{height:"10rem"}}>
                              <label>
                                Password <span className="required">&#42;</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  id="password-field"
                                />
                                <span className="input-group-text eye-icon" style={{   border: 'none',
        borderBottom: '1px solid #d3d3d3ba'}}>
                                  <i
                                    className="fa fa-eye-slash pointer"
                                    id="toggle-password"
                                    onClick={() => {
                                      const passwordField =
                                        document.getElementById("password-field");
                                      const toggleIcon =
                                        document.getElementById("toggle-password");
                                      if (passwordField.type === "password") {
                                        passwordField.type = "text";
                                        toggleIcon.classList.remove("fa-eye-slash");
                                        toggleIcon.classList.add("fa-eye");
                                      } else {
                                        passwordField.type = "password";
                                        toggleIcon.classList.remove("fa-eye");
                                        toggleIcon.classList.add("fa-eye-slash");
                                      }
                                    }}
                                  ></i>
                                </span>
                              </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group fieldsGroup col-12 mt-2">
                          <div className="rememberDays">
                            <ReCAPTCHA
                              sitekey={SITE_KEY} // Replace with your actual site key
                              onChange={(value) =>
                                handleCaptchaChange(value, setFieldValue)
                              }
                              name="captcha"
                            />
                            {touched.captcha && errors.captcha && (
                              <div className="error-message">
                                {errors.captcha}
                              </div>
                            )}
                          </div>
                          <div className="forgotPassword">
                            <a href="/forgot-password">Forgot password?</a>
                          </div>
                        </div>
                        <div className="form-group formSubmit signinButton col-12 mt-3 mb-4">
                          <button
                            type="submit"
                            className="btn"
                            disabled={!isValid || !captchaValue}
                          >
                            Login
                          </button>
                        </div>
                        {/* <div className="optionText mb-2">
                          <p>Or</p>
                        </div> */}
                        {/* <div className="signinButton">
                          <a
                            href={linkedinAuthUrl}
                            className="btn btnPrimaryOutline"
                            target="_self"
                          >
                            {" "}
                            <i className="fa fa-linkedin-square"></i> Sign In
                            with LinkedIn
                          </a>
                        </div> */}
                      </div>
                    </Form>
                  )}
                </Formik>

                {/* <div className="accountInfo mt-4">
                  <p className="m0 text-center">
                    Don't have an account?
                    <Link to="/sign-up-email"> Sign Up </Link>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
