import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import { Link, useLocation } from "react-router-dom";

import "./PaymentDetails.scss";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const PaymentDetails = () => {
  let [cardType, setCardType] = useState("");
  let [selectedSubscriptionData, setSelectedSubscriptionData] = useState("");
  const [errors, setErrors] = useState({});

  let [billingInfo, setBillingInfo] = useState({
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setSelectedSubscriptionData(location?.state);
  }, []);

  const handleCardTypeChange = (newCardType) => {
    setCardType(newCardType);
  };

  const validateAddress = (address) => {
    let validationErrors = {};

    if (!address.name) validationErrors.name = "Name is required.";
    if (!address.address.line1)
      validationErrors.line1 = "Address line 1 is required.";
    if (!address.address.city) validationErrors.city = "City is required.";
    if (!address.address.postal_code)
      validationErrors.postal_code = "Postal code is required.";
    if (!address.address.country)
      validationErrors.country = "Country is required.";

    return validationErrors;
  };

  const handleAddressChange = (event) => {
    const updatedAddress = event.value;
    setBillingInfo(updatedAddress);
    if (isSubmitted) {
      const validationErrors = validateAddress(updatedAddress);
      setErrors(validationErrors);
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const validationErrors = validateAddress(billingInfo);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Proceed with payment if no errors
      console.log("Form is valid. Proceeding with payment...");
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <div className="pageHeadingBlock buildYourICPHeading">
          <div className="pageTitle">
           
          </div>
        </div>
        <div className="pageContentBlock paymentsPage">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="billingDetails paymentCard">
                <div className="headingBlock">
                  <h5>Billing Information</h5>
                </div>
                <AddressElement
                  options={{
                    mode: "shipping",
                    // defaultValues: billingInfo,
                  }}
                  onChange={handleAddressChange}
                />
                {isSubmitted && Object.keys(errors).length > 0 && (
                <div className="error-message">
                  {Object.values(errors).map((error, index) => (
                    <p key={index} className="error-message">
                      {error}
                    </p>
                  ))}
                </div>
              )}
              </div>
              <div className="cardDetails paymentCard">
                <div className="headingBlock">
                  <h5>Payment Details</h5>
                </div>
                <div className="contentBlock">
                  <Tab.Container id="paymentCards">
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="debit-card"
                          onClick={() => {handleCardTypeChange("debit-card");handleSubmit()}}
                        >
                          <span className="circle"></span> Debit Card
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="credit-card"
                          onClick={() => {handleCardTypeChange("credit-card");handleSubmit()}}
                        >
                          <span className="circle"></span> Credit Card
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={cardType}>
                        {cardType && Object.keys(errors).length === 0 ? (
                          <PaymentForm
                            cardType={cardType}
                            selectedSubscriptionData={selectedSubscriptionData}
                            billingInfo={billingInfo}
                          />
                        ) : null}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="orderSummary paymentCard">
                <div className="headingBlock">
                  <h5>Order Summary</h5>
                </div>
                <div className="contentBlock">
                  <div className="choosedPlan">
                    <div className="planName">
                      <input type="checkbox" defaultChecked disabled />{" "}
                      {selectedSubscriptionData.package_name}
                    </div>

                    {/* <button type="submit" className="btn btnPrimaryOutline">
                      Change
                    </button> */}
                  </div>

                  <div className="choosedPlan">
                    <div className="planName">
                      <ul>
                        <li>
                          {selectedSubscriptionData?.data_download_credits} Data
                          Download Credits.
                        </li>
                        <li>
                          {selectedSubscriptionData?.number_of_searches} Number
                          of Searches.
                        </li>
                        <li>
                          Upto {selectedSubscriptionData?.page_visibility} Page
                          Visibility.
                        </li>

                        <li>
                          {
                            selectedSubscriptionData?.per_day_data_download_limit
                          }{" "}
                          data download per day.
                        </li>

                        {/* <li>
                          {selectedSubscriptionData?.prospect_download_credits}{" "}
                          prospect download credits.
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="choosedPlan">
                    <div className="planName">
                      Note: Please note that this message is not applicable to
                      first-time users. If you upgrade or downgrade your plan,
                      your charges will be adjusted based on your current
                      billing cycle. The amount shown here may be higher than
                      what you'll actually be charged.
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Description</th>

                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{selectedSubscriptionData.package_name}</td>

                          <td className="price">
                            ${selectedSubscriptionData.price}
                          </td>
                          <td className="subtotal">
                            {" "}
                            ${selectedSubscriptionData.price}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="amountBlock">
                    <ul>
                      <li>
                        Subtotal: <span>${selectedSubscriptionData.price}</span>
                      </li>
                      <li className="totalAmount">
                        Total Amount:{" "}
                        <span>${selectedSubscriptionData.price}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Elements>
    </>
  );
};

export default PaymentDetails;
