import React from "react";

const notyIconStyle = {
    position: "relative",
    display: "inline"
};
const notyNumStyle = {
    position: "absolute",
    right: "0",
    backgroundColor: "rgb(244, 146, 74)",
    fontSize: "14px",
    color: "white",
    display: "inline",
    padding: "1px 5px",
    borderRadius: "30px"
};
export default function Noty({ width, color, count }) {
    return (
        <div>
            <div style={notyIconStyle}>
                {count > 0 ? <div style={notyNumStyle}>{count}</div> : null}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                >
                    <path
                        d="M18.8202 21.3465C18.8202 19.4424 19.5766 17.6162 20.923 16.2698C22.2695 14.9234 24.0956 14.167 25.9997 14.167C27.9038 14.167 29.73 14.9234 31.0764 16.2698C32.4228 17.6162 33.1792 19.4424 33.1792 21.3465V24.8369C33.1792 25.6781 33.452 26.4966 33.957 27.1702L36.41 30.4405C36.61 30.7072 36.7317 31.0242 36.7617 31.3562C36.7916 31.6882 36.7285 32.022 36.5794 32.3201C36.4304 32.6182 36.2012 32.869 35.9177 33.0442C35.6341 33.2195 35.3074 33.3123 34.9741 33.3123H17.0253C16.692 33.3123 16.3653 33.2195 16.0817 33.0442C15.7982 32.869 15.569 32.6182 15.42 32.3201C15.2709 32.022 15.2078 31.6882 15.2377 31.3562C15.2677 31.0242 15.3894 30.7072 15.5894 30.4405L18.0424 27.1702C18.5473 26.4971 18.8202 25.6783 18.8202 24.8369V21.3465ZM25.9997 37.5003C25.0977 37.5002 24.2198 37.209 23.4966 36.67C22.7733 36.1311 22.2432 35.3732 21.9852 34.5089H30.0142C29.7562 35.3732 29.2261 36.1311 28.5028 36.67C27.7796 37.209 26.9017 37.5002 25.9997 37.5003Z"
                        fill="#414141"
                    />
                </svg>
            </div>
        </div>
    );
}
