import React, { useEffect, useState, useRef } from 'react';
import "./BuildMyCampaign.scss";
import { Button, Modal } from "react-bootstrap";

const AcceptModal = ({ showModal, onClose, getUserSubscriptionData, handleSubmitAccept }) => {
  const [error, setError] = useState("");
  const [checkedState, setCheckedState] = useState({
    all: false,
    content_syndication: false,
    market_qualified_lead: false,
    high_qualified_leads: false,
    bant_vpi: false,
    webinar_on_demand: false,
  });

  const [formData, setFormData] = useState({
    content_syndication: 0,
    market_qualified_lead: 0,
    high_qualified_leads: 0,
    bant_vpi: 0,
    webinar_on_demand: 0,
  });

  useEffect(() => {
    if (getUserSubscriptionData) {
      setFormData({
        content_syndication: getUserSubscriptionData?.content_syndication || 0,
        market_qualified_lead: getUserSubscriptionData?.market_qualified_lead || 0,
        high_qualified_leads: getUserSubscriptionData?.high_qualified_leads || 0,
        bant_vpi: getUserSubscriptionData?.bant_vpi || 0,
        webinar_on_demand: getUserSubscriptionData?.webinar_on_demand || 0,
      });
      setCheckedState({
        all: true,
        content_syndication: getUserSubscriptionData.content_syndication === 0 ? false : true,
        market_qualified_lead: getUserSubscriptionData.market_qualified_lead === 0 ? false : true,
        high_qualified_leads: getUserSubscriptionData.high_qualified_leads === 0 ? false : true,
        bant_vpi: getUserSubscriptionData.bant_vpi === 0 ? false : true,
        webinar_on_demand: getUserSubscriptionData.webinar_on_demand === 0 ? false : true,
      });
    }
  }, [getUserSubscriptionData]);

  const textRefs = {
    content_syndication: useRef(null),
    market_qualified_lead: useRef(null),
    high_qualified_leads: useRef(null),
    bant_vpi: useRef(null),
    webinar_on_demand: useRef(null),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("checkedState",formData)
    const payload = Object.keys(checkedState)
      .filter((key) => checkedState[key] === true)
      .reduce((acc, key) => {
        acc[key] = formData[key];
        return acc;
      }, {});
    handleSubmitAccept(payload);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = value === '' ? '' : parseInt(value, 10);
    const maxAmount = getUserSubscriptionData?.[name] || 0;

    if (newValue > maxAmount) {
      setError(`Value for ${name.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())} cannot exceed ${maxAmount}`);
      setFormData((prevData) => ({ ...prevData, [name]: maxAmount }));
    }else if(newValue<0){
      setError(`Value for ${name.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())} cannot less then  ${0}`);
      setFormData((prevData) => ({ ...prevData, [name]: 0 }));
    } else {
      setError("");
      setFormData((prevData) => ({ ...prevData, [name]: newValue }));
    }
  };

  const handleAllChange = () => {
    const newState = !checkedState.all;
    setCheckedState({
      all: newState,
      content_syndication: getUserSubscriptionData.content_syndication === 0 ? false : newState,
      market_qualified_lead: getUserSubscriptionData.market_qualified_lead === 0 ? false : newState,
      high_qualified_leads: getUserSubscriptionData.high_qualified_leads === 0 ? false : newState,
      bant_vpi: getUserSubscriptionData.bant_vpi === 0 ? false : newState,
      webinar_on_demand: getUserSubscriptionData.webinar_on_demand === 0 ? false : newState,
    });

    if (newState) {
      textRefs.content_syndication.current.focus();
    }
  };

 
  const handleCheckboxChange = (key) => {
    setCheckedState((prevState) => {
      const updatedState = { ...prevState, [key]: !prevState[key] };
      const allSelected = Object.keys(updatedState).every(
        (key) => key === 'all' || updatedState[key]
      );
      return { ...updatedState, all: allSelected };
    });

    if (!checkedState[key]) {
      textRefs[key].current.focus();
    }
  };

  const renderCheckboxWithInput = (key, label) => {
    const maxAmount = getUserSubscriptionData?.[key] || 0;
    return (
      <div className="row mb-3 align-items-center">
        <div className="col-md-6 mt-3">
          <label className="d-flex align-items-center">
            <input
              disabled={maxAmount === 0}
              style={{ cursor: maxAmount === 0 ? 'not-allowed' : 'pointer' }}
              type="checkbox"
              className="form-check-input me-2 m-0"
              checked={checkedState[key]}
              onChange={() => handleCheckboxChange(key)}
            />
            <span>{label}</span>
          </label>
        </div>
        <div className="col-md-6">
          <input
            id="quantity"
            type="number"
            className="form-control "
            name={key}
            min={0}
            value={formData[key]}
            placeholder={`Enter ${label}`}
            ref={textRefs[key]}
            onChange={handleChange}
            readOnly={!checkedState[key]}
          />
        </div>
      </div>
    );
  };
  const isAnySelected = Object.values(checkedState).includes(true);
  const total = Object.values(formData).reduce((acc, value) => acc + value, 0);
  console.log("total",total)
  return (
    <Modal className="AcceptModalModal mt-5" show={showModal} onHide={onClose} centered>
      <span className="closeModal" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" title="Close">
          <path d="M7.00047 8.0543L1.92737 13.1274C1.78892 13.2658 1.61489 13.3367 1.40527 13.3399C1.19567 13.3431 1.01844 13.2723 0.873575 13.1274C0.728691 12.9825 0.65625 12.8069 0.65625 12.6005C0.65625 12.3941 0.728691 12.2184 0.873575 12.0736L5.94665 7.00047L0.873575 1.92737C0.735108 1.78892 0.664275 1.61489 0.661075 1.40527C0.657858 1.19567 0.728691 1.01844 0.873575 0.873575C1.01844 0.728691 1.19407 0.65625 1.40047 0.65625C1.60687 0.65625 1.78251 0.728691 1.92737 0.873575L7.00047 5.94665L12.0736 0.873575C12.212 0.735108 12.3861 0.664275 12.5957 0.661075C12.8053 0.657858 12.9825 0.728691 13.1274 0.873575C13.2723 1.01844 13.3447 1.19407 13.3447 1.40047C13.3447 1.60687 13.2723 1.78251 13.1274 1.92737L8.0543 7.00047L13.1274 12.0736C13.2658 12.212 13.3367 12.3861 13.3399 12.5957C13.3431 12.8053 13.2723 12.9825 13.1274 13.1274C12.9825 13.2723 12.8069 13.3447 12.6005 13.3447C12.3941 13.3447 12.2184 13.2723 12.0736 13.1274L7.00047 8.0543Z" fill="black" />
        </svg>
      </span>
      <Modal.Body className="modal-body text-center saveFiltersModalBody">
        <h2>Accept Campaign</h2>
        {error && <b style={{ color: "red" }}>*{error}</b>}
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="d-flex align-items-center">
                <input
                  disabled={!getUserSubscriptionData}
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={checkedState.all}
                  onChange={handleAllChange}
                />
                <span>Select All</span>
              </label>
            </div>
          </div>
          {renderCheckboxWithInput("content_syndication", "Content Syndication")}
          {renderCheckboxWithInput("market_qualified_lead", "Market Qualified Lead")}
          {renderCheckboxWithInput("high_qualified_leads", "High Qualified Leads")}
          {renderCheckboxWithInput("bant_vpi", "BANT VPI")}
          {renderCheckboxWithInput("webinar_on_demand", "Webinar on Demand")}
          <Button disabled={!isAnySelected||Number(total)===0} className="btn btn-primary" type="submit">Accept</Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptModal;
