import React, { useEffect, useState } from 'react'
import "./BuildMyCampaign.scss";
import { isEmptyArray } from 'formik';

const TableChart = ({ tableRow }) => {
    const [headers, setHeaders] = useState([])
    useEffect(() => {
        if (Array.isArray(tableRow) && tableRow?.length > 0) {
            const headerKeys = Object.keys(tableRow[0]);
            setHeaders(headerKeys)
        }

    }, [tableRow])
    // console.log("tableRow",tableRow)
    return (
        < >
            <table class="table table-warning m-auto ms-5 customTable text-center" style={{ padding:'20px' }}>
                <thead>
                    <tr>
                        {Array.isArray(headers)&&headers?.length > 0 && headers.map((ele, index) => {
                            return (
                                <th key={index} scope="col" className={`${index === 0 ? 'tbhead' : index === 1 ? 'rounded' : ''} text-center-camapaign-table font-bold-camapaign-table`}>{ele}
                                </th>
                            )
                        })}

                    </tr>
                </thead>
                <tbody className='trhead' >
                    {Array.isArray(tableRow) && tableRow.length > 0 && tableRow.map((obj, item) => {
                        return (
                            <tr>
                                {headers.length > 0 && headers.map((ele, index) =>
                                    <th 
                                    scope={index === 0 ? "row" : null} 
                                    className={`${index === 0 ? 'tbhead' : ''} text-center-camapaign-table`}
                                  >
                                    {obj[ele]}
                                  </th>
                                )}
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </>
    )
}

export default TableChart