import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link } from "react-router-dom";
import "./SupportTicket.scss";
import SupportTicketTable from "./SupportTicketTable";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTicketsFromList,
  getListOfSupportTickits,
} from "../../context/actions/User";
import { toast } from "react-toastify";
import { handleCreateConfirmation } from "../../components/Modal/ConfirmBox";

const SupportTicket = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const handle = useFullScreenHandle();
  //get list of support tickit
  const [getListData, setGetListData] = useState([]);
  //storing list data if not necessary remove
  const [abc, setAbc] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [dataToRender, setDataToRender] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // for sorting
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });

  //for checkbox selection
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkSelectAll, setCheckSelectAll] = useState([]);

  const itemPerPage = 100;

  useEffect(() => {
    const payload = {
      user_id: userId,
    };
    dispatch(
      getListOfSupportTickits(payload, token, (result) => {
        setGetListData(result);

        setAbc(result);
        const total = Math.ceil(Math.max(result?.length, 0) / itemPerPage);
        setTotalPages(total);

        const startIndex = (currentPage - 1) * itemPerPage;
        const endIndex = startIndex + itemPerPage;
        const paginatedData = result?.slice(startIndex, endIndex);
        setDataToRender(paginatedData);
      })
    );
  }, [dispatch, userId, currentPage]);

  //pagenumbers in the dropdown box

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      const checkExistSelect = checkSelectAll.includes(pageNumber);
      setSelectAll(checkExistSelect);
      filterData(searchInput, pageNumber);
      // setSelectedRowId(null); // Reset selected row when changing pages
    }
  };
  //search input
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    handlePageChange(1);
    filterData(input, 1);
  };
  // data to be shown with pagination
  const filterData = (input, pageNumber) => {
    const lowercasedInput = input.toLowerCase();
    const filtered = getListData.filter(
      (data) =>
        data?.issue_category.toLowerCase().includes(lowercasedInput) ||
        data?.issue_sub_category.toLowerCase().includes(lowercasedInput) ||
        data?.explaination.toLowerCase().includes(lowercasedInput) ||
        data?.priority.toLowerCase().includes(lowercasedInput) ||
        data?.status.toLowerCase().includes(lowercasedInput)||
        data?.ticket_no.toLowerCase().includes(lowercasedInput)
    );

    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    let paginatedData = filtered.slice(startIndex, endIndex);

    setCurrentPage(pageNumber);
    setDataToRender(paginatedData);
  };
  //according to selection of all
  useEffect(() => {}, [checkSelectAll]);
  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };

  const sortedData = [...dataToRender]?.sort((a, b) => {
    const columnA = a[sortBy.column];
    const columnB = b[sortBy.column];

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
      setSelectAll(false); // Uncheck the header checkbox if any individual checkbox is unchecked
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);

      if (selectedRows.length + 1 === sortedData.length) {
        setSelectAll(true); // Check the header checkbox if all individual checkboxes are checked
      }
    }
  };

  const handleDelete = () => {
    if (selectedRows.length === 0) {
      toast("Please select checkbox to delete!", { autoClose: 1000 });
    } else {
      handleCreateConfirmation(
        selectedRows,
        (selectedRows) => {
          dispatch(
            deleteTicketsFromList(selectedRows, token, (result) => {
              if (result.status === 200) {
                toast(result.message, { autoClose: 1000 });
                // After successful deletion, fetch the updated list of tickets
                const payload = { user_id: userId };
                setSelectedRows([]);
                dispatch(
                  getListOfSupportTickits(payload, token, (updatedResult) => {
                    // Update the state with the updated list
                    setGetListData(updatedResult);
                    setAbc(updatedResult);

                    // Calculate total pages and pagination data
                    const total = Math.ceil(
                      Math.max(updatedResult?.length, 0) / itemPerPage
                    );
                    setTotalPages(total);
                    const startIndex = (currentPage - 1) * itemPerPage;
                    const endIndex = startIndex + itemPerPage;
                    const paginatedData = updatedResult?.slice(
                      startIndex,
                      endIndex
                    );
                    setDataToRender(paginatedData);
                  })
                );
              }
            })
          );
        },
        () => {
          return false;
        }
      );
    }
  };

  const isDisabled = selectedRows < 1;

  return (
    <>
      <div className="pageHeadingBlock SupportTicketsHeading">
        <div className="pageTitle">
          <h3 className="m-0">Support Tickets</h3>
        </div>
        <div className="raiseTicket mb-3">
          <Link to="/raise-new-ticket" className="btn">
            Raise New Ticket
          </Link>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper SupportTickets">
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      {/* <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                    <div className={`deleteSelection d-flex align-items-center`}>
                      <button
                        type="button"
                        className="btn transparentBtn"
                        disabled={selectedRows.length < 1}
                        onClick={handleDelete}
                      >
                        {/* <p
                        className="d-flex align-items-center m-0"
                        onClick={isDisabled ? null : handleDelete}
                      > */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.08975 17.0822C5.67549 17.0822 5.32085 16.9347 5.02585 16.6397C4.73084 16.3447 4.58333 15.99 4.58333 15.5758V4.99886H4.375C4.19792 4.99886 4.04948 4.93894 3.92969 4.81909C3.8099 4.69926 3.75 4.55076 3.75 4.37359C3.75 4.19644 3.8099 4.04804 3.92969 3.92839C4.04948 3.80872 4.19792 3.74888 4.375 3.74888H7.49998C7.49998 3.54483 7.57183 3.37096 7.71552 3.22726C7.85922 3.08357 8.03309 3.01172 8.23715 3.01172H11.7628C11.9669 3.01172 12.1407 3.08357 12.2844 3.22726C12.4281 3.37096 12.5 3.54483 12.5 3.74888H15.625C15.802 3.74888 15.9505 3.80881 16.0703 3.92866C16.1901 4.0485 16.25 4.197 16.25 4.37416C16.25 4.55132 16.1901 4.69973 16.0703 4.81939C15.9505 4.93904 15.802 4.99886 15.625 4.99886H15.4166V15.5758C15.4166 15.99 15.2691 16.3447 14.9741 16.6397C14.6791 16.9347 14.3245 17.0822 13.9102 17.0822H6.08975ZM14.1666 4.99886H5.83331V15.5758C5.83331 15.6506 5.85735 15.712 5.90544 15.7601C5.95352 15.8082 6.01496 15.8322 6.08975 15.8322H13.9102C13.985 15.8322 14.0464 15.8082 14.0945 15.7601C14.1426 15.712 14.1666 15.6506 14.1666 15.5758V4.99886ZM8.46181 14.1655C8.63897 14.1655 8.78737 14.1056 8.90702 13.9858C9.02669 13.8661 9.08652 13.7176 9.08652 13.5406V7.29051C9.08652 7.11344 9.0266 6.96501 8.90675 6.84522C8.7869 6.72543 8.6384 6.66553 8.46125 6.66553C8.28408 6.66553 8.13567 6.72543 8.01602 6.84522C7.89637 6.96501 7.83654 7.11344 7.83654 7.29051V13.5406C7.83654 13.7176 7.89647 13.8661 8.01631 13.9858C8.13615 14.1056 8.28465 14.1655 8.46181 14.1655ZM11.5387 14.1655C11.7159 14.1655 11.8643 14.1056 11.9839 13.9858C12.1036 13.8661 12.1634 13.7176 12.1634 13.5406V7.29051C12.1634 7.11344 12.1035 6.96501 11.9836 6.84522C11.8638 6.72543 11.7153 6.66553 11.5381 6.66553C11.361 6.66553 11.2126 6.72543 11.0929 6.84522C10.9733 6.96501 10.9134 7.11344 10.9134 7.29051V13.5406C10.9134 13.7176 10.9734 13.8661 11.0932 13.9858C11.2131 14.1056 11.3616 14.1655 11.5387 14.1655Z"
                            fill="#414141"
                          />
                        </svg>{" "}
                        Delete Selection
                      </button>{" "}
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <span className="pagination-page-block">
                      <strong>Total Records : {getListData?.length}</strong>
                    </span>{" "}
                    <div className="d-flex align-items-center">
                      <span
                        style={{ marginLeft: "5rem" }}
                        className="page-span"
                      >
                        {" "}
                        Page :
                      </span>
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div className="prev paginationItem">
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="next paginationItem">
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FullScreen handle={handle}>
                <SupportTicketTable
                  getListData={getListData}
                  handleSort={handleSort}
                  sortedData={sortedData}
                  sortBy={sortBy}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  // handleSelectAll={handleSelectAll}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportTicket;
