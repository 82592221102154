import React, { useEffect, useState } from "react";
import "./subheader.scss";

const SubHeader = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const toggleVisibility = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };
  useEffect(() => {
    // Check if user has seen the notice board before
    const hasSeenNoticeBoard = localStorage.getItem('hasSeenNoticeBoard');
    console.log("hasSeenNoticeBoard",hasSeenNoticeBoard)
    if (!hasSeenNoticeBoard) {
      // If it's the user's first time, show the notice board
      setIsVisible(true);
      
      // Set a flag in localStorage so we don't show it again in the future
      localStorage.setItem('hasSeenNoticeBoard', 'true');
    }
  }, []);

  return (
    <>
      {isVisible  && (
        <div style={{background:data.color}}
          className={`container-fluid MainContent ${isFadingOut ? "fade-out" : ""}`}
        >
          <div className="row d-flex justify-content-between">
            <div className="col-10 my-1 col-md-10">
              <div
                className="row d-flex align-items-center justify-content-center maincontentrow"
              >
                <div className="col-auto col-md-1 text-end ms-3 ms-md-5 text-md-end">
                  <i
                    className="pi pi-database p-3 bg-white leftIcon"
                  ></i>
                </div>
                <div
                  className="col-9 col-md-10 pt-1 ms-2 ms-md-0 contentCol"
                >
                  <b className="contentHead">{data?.name}</b>
                  <br />
                  <span className="contentSubHead">
                    {data?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-2 col-md-1 text-start me-0 me-md-5">
              <h3
                className="my-3 crossicon"
                onClick={toggleVisibility}
              >
                x
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubHeader;
