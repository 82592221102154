import React from "react";
import { useSelector } from "react-redux";
import "./loading.scss";
import TopBarProgress from "react-topbar-progress-indicator";
// import logo from "../../assets/videos/logogif.gif"
import logo from "../../assets/videos/logo.gif"
// import logo from "../../assets/videos/logo.mp4"

// Progress Bar
TopBarProgress.config({
  barColors: {
    "0": "#ff4500",
    "1.0": "#ff4500"
  },
  shadowBlur: 5
});
// Progress Bar END

function Loader() {
  const loading = useSelector((state) => state.loading.isLoading);
  
  return (
    <>
      {loading && (
        <>
        <div className="loader-wrapper">
         <div className="">
         {/* <video className="img-fluid" controls="" autoplay muted width="200px" style={{backgroundImage:"none"}} >
           <source src={logo} />
           <source src={logo} type="video/ogg" />

          </video> */}
          <img className="img-fluid" src={logo} alt="" style={{width: "350px"}}/>
         </div>
        </div>
        <TopBarProgress />
        </>
      )}
    </>
  );
}

export default Loader;
