import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useNavigate } from "react-router-dom";
import "./Users.scss";
import {
  getAllStandardUsers,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "./UserTable";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { toast } from "react-toastify";
import Information from "../BuildICP/ICPResult/Information";

const UserList = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [getUserListData, setUserListData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  const handleUsers = () => {
    const payload = { user_id: userId };
    dispatch(
      getAllStandardUsers(payload, token, (result) => {
        setUserListData(result);
      })
    );
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  };

  useEffect(() => {
    handleUsers();
  }, [dispatch, userId]);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };

  // Filtering user list based on search input for name or email
  const filteredUserList = getUserListData.filter(
    (user) =>
      user.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      user.email.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      <div className="pageHeadingBlock commonHeading">
        <ICPTitle title="Manage Users" backTo="/dashboard" />
        <Information getUserSubscriptionData={getUserSubscriptionData} />
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper myDownloadList">
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      {/* <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </form>
                </div>
                {getUserSubscriptionData?.license - 1 >
                  getUserListData.length && (
                  <div className="raiseTicket">
                    <Link to="/manage-users/add-user" className="btn">
                      Add User
                    </Link>
                  </div>
                )}
              </div>
              <FullScreen handle={handle}>
                <div className="tableContentBlock table-responsive">
                  <UserTable
                    getUserListData={filteredUserList}
                    handleUsers={handleUsers}
                  />
                </div>
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
