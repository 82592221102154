import "rc-slider/assets/index.css";
import React from "react";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { handleCreateConfirmation } from "../../components/Modal/ConfirmBox";
import {
  deleteStandardUser,
  blockStandardUser,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";

const UserTable = ({ getUserListData, handleUsers }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const handleEdit = (id) => {
    navigate(`/manage-users/edit-user/${id}`);
  };

  const handleDelete = (id) => {
    handleCreateConfirmation(
      id,
      (id) => {
        console.log("Delete user with ID:", id);
        dispatch(
          deleteStandardUser(id, token, (result) => {
            if (result.status === 200) {
              handleUsers();
            }
          })
        );
      },
      () => {
        return false;
      }
    );
  };

  const handleBlock = (id) => {
    handleCreateConfirmation(
      id,
      (id) => {
        dispatch(
          blockStandardUser(id, token, (result) => {
            if (result.status === 200) {
              handleUsers();
            }
          })
        );
      },
      () => {
        return false;
      }
    );
  };

  return (
    <Table>
      <thead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Credit Amount</th>
          {/* <th>Prospect Download Credits</th> */}
          <th>Searches Left</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {getUserListData.length > 0 ? (
          getUserListData?.map((data) => (
            <tr key={data.id}>
              <td>{data.name}</td>
              <td>{data.email}</td>
              <td>{data.credit_amount}</td>
              {/* <td>{data.prospect_download_credits}</td> */}
              <td>{data.searches_left}</td>
              <td>
                <InputSwitch
                  checked={data.is_active}
                  onChange={() => handleBlock(data.id)}
                  color="primary"
                  inputProps={{ "aria-label": "Block Switch" }}
                />
              </td>
              <td>
                <div
                  className="icon-wrapper"
                  title="Edit"
                  onClick={() => handleEdit(data.id)}
                >
                  <i className="fa fa-pencil"></i>
                </div>
                <div
                  className="icon-wrapper"
                  title="Delete"
                  onClick={() => handleDelete(data.id)}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan={7}>
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default UserTable;
