import React, { useEffect } from 'react'
import "../../components/Header/header.scss";
import { Link } from 'react-router-dom';


export default function Notifications({ data, readNotification, modalRef,closeModal }) {
    useEffect(() => {
        let ids = data
            .filter(ele => !ele.is_read)
            .map(ele => ele.id);

        if (ids.length > 0) {
            readNotification({ ids })
        }
    }, [])


    return (
        <div className="dropdown-menu show" ref={modalRef}>
            <div className="notificationHeading">
                <h5>Notifications</h5>
                <p className="markRead" onClick={() => readNotification({ is_all: true })}>Mark all as Read</p>
            </div>
            <div className="notificationList">
                {
                    (data && data.length) ? data.map((item, index) => {

                        return (
                            <div key={index} className="listItem updateNotification">
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M20.0002 36.6663C29.2049 36.6663 36.6668 29.2044 36.6668 19.9997C36.6668 10.7949 29.2049 3.33301 20.0002 3.33301C10.7954 3.33301 3.3335 10.7949 3.3335 19.9997C3.3335 23.9946 4.73907 27.6613 7.08266 30.5322L3.82092 33.8212C2.77968 34.8711 3.51713 36.6663 4.98966 36.6663H20.0002ZM12.4932 16.9971C12.4932 16.1686 13.1647 15.4971 13.9932 15.4971H26.0723C26.9007 15.4971 27.5723 16.1686 27.5723 16.9971C27.5723 17.8255 26.9007 18.4971 26.0723 18.4971H13.9932C13.1647 18.4971 12.4932 17.8255 12.4932 16.9971ZM13.9932 21.5022C13.1647 21.5022 12.4932 22.1738 12.4932 23.0022C12.4932 23.8306 13.1647 24.5022 13.9932 24.5022H19.3193C20.1478 24.5022 20.8193 23.8306 20.8193 23.0022C20.8193 22.1738 20.1478 21.5022 19.3193 21.5022H13.9932Z"
                                            fill="#E55426"
                                        />
                                    </svg>
                                </div>
                                <h6>{item.titel}</h6>
                                <p>{item.message} </p>
                                <div className="notificationTime">{item.duration} ago</div>
                            </div>
                        )
                    }) : (
                        <div><h6 className='text-center my-2'>
                            No data found</h6></div>
                    )
                }
                 <hr />
                <div className="notificationFooter " >

                    <Link to="view-all-notifications" 
                        state={{ notification: data }}
                        className=' col-md-12  float-end px-2 pb-3' ><button onClick={(e)=>closeModal(e)} id='modelClose' className='applyBtnSmall mx-auto col-3' >View all </button></Link>

                </div>

            </div>

        </div>
    )
}
